import { useMemo } from 'react';

import { graphql } from '../../../gql';
import {
  type ControllersForSecurityApplianceQueryQuery,
  type ControllersQueryQuery,
  type HaConfigForNetworkQueryQuery,
  PermissionType,
} from '../../../gql/graphql';
import { useGetAccessLevelForUser } from '../../HasAccess/useAccessLevelForUser';

export enum ValidSecurityAppliancesTab {
  Current = 'list',
  Spares = 'spares',
}

export type ControllersQueryResult = ControllersQueryQuery['virtualDevicesForNetwork'][number];
export type ControllersForSecurityApplianceResult =
  ControllersForSecurityApplianceQueryQuery['virtualDevicesForNetwork'][number];

export type HighAvailabilityConfig = NonNullable<
  HaConfigForNetworkQueryQuery['network']['highAvailabilityConfig']
>;

graphql(`
  fragment ControllerFragment on VirtualDevice {
    __typename
    UUID
    label
    description
    deviceType
    deviceModel
    isConsoleEnabled
    networkUUID
    uptime
    nosUpgradeGroup @include(if: $includeNOSUpgradeGroup) {
      UUID
    }
    nosVersion {
      id
      version
      buildName
    }
    pendingNosVersion {
      nosVersion {
        version
      }
      scheduledAt
    }

    hardwareDevice {
      __typename
      deviceType
      serialNumber
      macAddress
      deviceModel
      isActive
      isConnectedToBackend
      disconnectedFromBackendAt
      uptime
      bootHistory(count: 20) {
        bootCount
        buildName
        rebootReason
        createdAt
      }
      isDev @include(if: $includeIsDev)
    }

    ... on ControllerVirtualDevice {
      highAvailability {
        role
        status
      }
      securityApplianceProfile @include(if: $includeSecurityApplianceProfile) {
        UUID
        multiWANAlgorithm
        multiWANUpdateLookback
        multiWANUpdateThreshold
        multiWANUpdateCooldownSec
        hostMonitoringTimeoutSec
        hostMonitoringIntervalSec
      }
      managementConnection @include(if: $includeManagementConnection) {
        connectionType
      }
    }
  }
`);

export const ControllersQuery = graphql(`
  query ControllersQuery($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {
      __typename
      UUID
      label
      description
      deviceType
      deviceModel
      uptime
      nosVersion {
        id
        version
        buildName
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }
      }
    }
  }
`);

export function useControllerQueryVariables(uuid: string) {
  const getAccessLevel = useGetAccessLevelForUser();
  return useMemo(
    () => ({
      uuid,
      includeIsDev: !!getAccessLevel({
        permissions: PermissionType.PermNetworkDevicesReadRestricted,
      }),
      includeSecurityApplianceProfile: !!getAccessLevel({
        permissions: PermissionType.PermSecurityApplianceProfileRead,
      }),
      includeManagementConnection: !!getAccessLevel({
        permissions: PermissionType.PermNetworkDevicesReadRestricted,
      }),
      includeNOSUpgradeGroup: !!getAccessLevel({ permissions: PermissionType.PermNosRead }),
    }),
    [uuid, getAccessLevel],
  );
}

export const ControllerQuery = graphql(`
  query ControllerQuery(
    $uuid: UUID!
    $includeIsDev: Boolean = false
    $includeSecurityApplianceProfile: Boolean = false
    $includeManagementConnection: Boolean = false
    $includeNOSUpgradeGroup: Boolean = false
  ) {
    virtualDevice(UUID: $uuid) {
      ...ControllerFragment

      ... on ControllerVirtualDevice {
        phyInterfaces {
          ...ControllerPhyInterfaceFields
        }
      }
    }
  }
`);

export const HAConfigForNetworkQuery = graphql(`
  query HAConfigForNetworkQuery($networkUUID: UUID!) {
    network(UUID: $networkUUID) {
      UUID
      highAvailabilityConfig {
        UUID
        primaryPhyInterface {
          UUID
          portNumber
          label
          virtualDevice {
            UUID
            label
            deviceType
            hardwareDevice {
              isConnectedToBackend
            }
          }
        }
        backupPhyInterface {
          UUID
          portNumber
          label
          virtualDevice {
            UUID
            label
            deviceType
            hardwareDevice {
              isConnectedToBackend
            }
          }
        }
        advertisementVLAN {
          UUID
          vlanID
          name
        }
        preemption
        advertisementIntervalMs
      }
    }
  }
`);

graphql(`
  fragment ControllerVirtualDeviceFragment on ControllerVirtualDevice {
    highAvailability {
      role
      status
    }

    outletInterfaceConnectedTo {
      UUID
      label
      outletNumber
      virtualDevice {
        UUID
        label
      }
    }

    phyInterfaces {
      UUID
      isConnected
      isEnabled
      isSFP
      isTrunkPort
      isUplink
      label
      portNumber
      portSpeedMbps

      connectedDevices {
        hardwareDevice {
          deviceModel
          deviceType
          virtualDevice {
            UUID
            label
            deviceType
          }
        }
      }

      hasWANActivity
      internetServicePlan {
        controllerIP
        provider {
          name
          logoURL
        }
      }
    }
  }
`);

export const ControllerForSecurityApplianceQuery = graphql(`
  query ControllerForSecurityApplianceQuery(
    $uuid: UUID!
    $includeIsDev: Boolean = false
    $includeSecurityApplianceProfile: Boolean = false
    $includeManagementConnection: Boolean = false
    $includeNOSUpgradeGroup: Boolean = false
  ) {
    virtualDevice(UUID: $uuid) {
      ...ControllerFragment

      ... on ControllerVirtualDevice {
        ...ControllerVirtualDeviceFragment
      }
    }
  }
`);

export const ControllersForSecurityApplianceQuery = graphql(`
  query ControllersForSecurityApplianceQuery(
    $networkUUID: UUID!
    $includeIsDev: Boolean = false
    $includeSecurityApplianceProfile: Boolean = false
    $includeManagementConnection: Boolean = false
    $includeNOSUpgradeGroup: Boolean = false
  ) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {
      ...ControllerFragment

      ... on ControllerVirtualDevice {
        ...ControllerVirtualDeviceFragment
        phyInterfaces {
          ...ControllerPhyInterfaceFields
        }
      }
    }
  }
`);

export const LatestDeviceMemoryUsageQuery = graphql(`
  query LatestDeviceMemoryUsage($serialNumber: String!) {
    latestDeviceMemoryUsage(serialNumber: $serialNumber) {
      bytesUsed
      bytesAvailable
    }
  }
`);

export const DeviceUptimeQuery = graphql(`
  query DeviceUptime($serialNumber: String!) {
    deviceUptime(serialNumber: $serialNumber)
  }
`);

export const LatestDeviceCPULoadQuery = graphql(`
  query LatestDeviceCPULoad($serialNumber: String!) {
    latestDeviceCPULoad(serialNumber: $serialNumber) {
      usedPercentage
    }
  }
`);

export const DeviceConfigVersionQuery = graphql(`
  query DeviceConfigVersion($serialNumber: String!) {
    latestDeviceConfig(serialNumber: $serialNumber) {
      version
    }
    latestAcknowledgedDeviceConfig(serialNumber: $serialNumber) {
      version
      acknowledgedAt
    }
  }
`);

export const DeadMansSnitchQuery = graphql(`
  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      token
    }
  }
`);

export const fallbackControllerHardwareDeviceQuery = graphql(`
  query FallbackSwitchHardwareQuery($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      virtualDeviceUUID
    }
  }
`);

export const createHighAvailabilityConfigMutation = graphql(`
  mutation CreateHighAvailabilityConfig(
    $networkUUID: UUID!
    $primaryPhyInterfaceUUID: UUID!
    $backupPhyInterfaceUUID: UUID!
    $input: CreateHighAvailabilityPairInput!
  ) {
    createHighAvailabilityControllersConfiguration(
      networkUUID: $networkUUID
      primaryPhyInterfaceUUID: $primaryPhyInterfaceUUID
      backupPhyInterfaceUUID: $backupPhyInterfaceUUID
      input: $input
    ) {
      UUID
      advertisementVLAN {
        networkUUID
      }
    }
  }
`);

export const updateHighAvailabilityConfigMutation = graphql(`
  mutation UpdateHighAvailabilityConfig($uuid: UUID!, $input: UpdateHighAvailabilityPairInput!) {
    updateHighAvailabilityControllersConfiguration(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteHighAvailabilityConfigMutation = graphql(`
  mutation DeleteHighAvailabilityConfig($uuid: UUID!) {
    deleteHighAvailabilityControllersConfiguration(UUID: $uuid) {
      UUID
    }
  }
`);

export const DeviceLastDayCellularUsageQuery = graphql(`
  query DeviceLastDayCellularUsage($serialNumber: String!) {
    deviceLastDayCellularUsage(serialNumber: $serialNumber) {
      uploadBytes
      downloadBytes
    }
  }
`);

export const DeviceCellularStatusQuery = graphql(`
  query DeviceCellularStatus($serialNumber: String!) {
    deviceCellularStatus(serialNumber: $serialNumber) {
      managerModemState
      observedAt
    }
  }
`);
