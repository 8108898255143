import type { SpacingScale } from '@meterup/atto';
import { sizing } from '@meterup/atto';

export const targetValues = {
  padding: {
    ends: { '@desktop': 4, '@notDesktop': 8 },
    sides: { '@desktop': 8, '@notDesktop': 12 },
  },
  borderRadius: { '@desktop': 8, '@notDesktop': 10 },
};

export const dockValues = {
  width: {
    maximized: {
      '@tablet': 272 as SpacingScale,
      '@desktop': 232 as SpacingScale,
    },
    minimized: {
      '@tablet': 64 as SpacingScale,
      '@desktop': 52 as SpacingScale,
    },
  },
  padding: {
    ends: { '@notDesktop': 28 as SpacingScale, '@desktop': 16 as SpacingScale },
    sides: { '@notDesktop': 28 as SpacingScale, '@desktop': 20 as SpacingScale },
  },
  dropdown: {
    '@tablet': 244 as SpacingScale,
    '@desktop': 208 as SpacingScale,
  },
  transition: {
    in: 'left 0.2s linear',
    inDelay: 0,
    out: 'left 0.2s linear',
    outDelay: '0.4s',
  },
};

export const applicationValues = {
  padding: {
    ends: sizing.secondary,
    sides: sizing.primary,
  },
  ends: {
    height: { '@desktop': 48, '@notDesktop': 52 },
  },
};
