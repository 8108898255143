import type { HardwareIconName, PortPropSpeed } from '@meterup/atto';
import { Badge, Body, DeviceTarget, DeviceTargets, HStack, styled } from '@meterup/atto';
import {
  getManufacturerIconName,
  gigabytes,
  isDefinedAndNotEmpty,
  lookupMACAddressOUI,
  useOuiDataMap,
} from '@meterup/common';
import { useCallback, useMemo } from 'react';
import { type To, useNavigate } from 'react-router';
import { bytes, Measure } from 'safe-units';
import { z } from 'zod';

import type {
  ControllersForSecurityApplianceQueryQuery,
  MacTableQueryQuery,
  PortsQueryQuery,
  SwitchesQueryQuery,
  SwitchesRootQueryQuery,
  SwitchPortStatsQueryQuery,
  SwitchQueryQuery,
} from '../../../gql/graphql';
import type { RackElevationDevice } from '../../Design/RackElevations/utils';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import {
  PermissionType,
  PhyInterface8021XmacAuthenticationBypassMode,
  TrafficDirection,
  VirtualDeviceType,
} from '../../../gql/graphql';
import {
  UpdatePhyInterfaceInputSchema as BaseSchema,
  UpdateSwitchProfileInputSchema,
  UpdateVirtualDeviceInputSchema,
} from '../../../gql/zod-types';
import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../../utils/types';
import { getDeviceDrawerLink } from '../../Devices/utils';
import { useGetAccessLevelForUser } from '../../HasAccess/useAccessLevelForUser';
import { NoValue } from '../../NoValue';
import { ReactRouterLink } from '../../ReactRouterLink';
import { ValidAPDrawerTab } from '../../Wireless/utils';

export enum ValidSwitchesTab {
  Current = 'list',
  Spares = 'spares',
}

export enum ValidSwitchTab {
  Insights = 'insights',
  Ports = 'ports',
  MACTable = 'mac-table',
  BootHistory = 'boot-history',
}

export type { VLANsQueryVLAN as VLANQueryResult } from '../../NetworkWide/VLANs/utils';

export type SwitchesRootQueryResult = SwitchesRootQueryQuery['virtualDevicesForNetwork'][number];
export type PhyInterfaceQueryResult = PortsQueryQuery['phyInterfacesForVirtualDevice'][number];

export type SwitchesQueryResult = SwitchesQueryQuery['virtualDevicesForNetwork'][number];
export type MACTableQueryResult = MacTableQueryQuery['switchMACTable'][number];

export const UpdatePhyInterfaceInputSchema = BaseSchema.extend({
  frameAcceptTypeFilter: z.enum(['ALL', 'UNTAGGED_ONLY', 'TAGGED_ONLY']).nullish(),
  maxMACAddressesAllowed: z
    .number()
    .refine((val) => val >= 1 && val <= 256, { message: 'Must be between 1 and 256' })
    .nullish(),
  stpPathCost: z
    .number()
    .refine((val) => val >= 1 && val <= 200000000, { message: 'Must be between 1 and 200000000' })
    .nullish(),
  stpPriority: z
    .number()
    .refine((val) => val >= 0 && val <= 240 && val % 16 === 0, {
      message: 'Must be between 0 and 240 and divisible by 16',
    })
    .nullish(),
});

export type ValidPhyInterfaceParams = z.infer<typeof UpdatePhyInterfaceInputSchema>;

export const ValidPortSpeeds = [10, 100, 1000, 2500, 10000] as const;
export type ValidPortSpeed = (typeof ValidPortSpeeds)[number];

export const ValidSTPBridgePriorities = [
  0, 4096, 8192, 12288, 16384, 20480, 24576, 28672, 32768,
] as const;
export type ValidSTPBridgePriority = (typeof ValidSTPBridgePriorities)[number];

export const ValidSTPPriorities = [
  16, 32, 48, 64, 80, 96, 112, 128, 144, 160, 176, 192, 208, 224, 240,
] as const;
export type ValidSTPPriority = (typeof ValidSTPPriorities)[number];

export const fallbackSwitchHardwareDeviceQuery = graphql(`
  query FallbackSwitchHardwareQuery($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      virtualDeviceUUID
    }
  }
`);

export const updateSwitchVirtualDeviceSchema = UpdateVirtualDeviceInputSchema.pick({
  label: true,
  description: true,
  enableConsolePort: true,
  nosUpgradeGroupUUID: true,
}).extend({
  label: z.string().nonempty({ message: 'Please provide a label.' }),
});
export type ValidSwitchVirtualDeviceParams = z.input<typeof updateSwitchVirtualDeviceSchema>;

export const updateSwitchProfileInputSchema = UpdateSwitchProfileInputSchema.extend({
  // Make this required (its nullish in the base schema)
  stpBridgePriority: z.number(),
  isFLIPEnabled: z.boolean(),
  isEncryption8021XEnabled: z.boolean(),
  primaryEncryption8021XUUID: z.string().nullish(),
  secondaryEncryption8021XUUID: z.string().nullish(),
  mtu: z
    .number()
    .refine((v) => v >= 1522 && v <= 10240, { message: 'MTU must be between 1522 and 10240' }),
})
  .refine(
    ({ isVoiceVLANEnabled, voiceVLANUUID }) =>
      !isVoiceVLANEnabled || isDefinedAndNotEmpty(voiceVLANUUID),
    { message: 'Voice VLAN required', path: ['voiceVLANUUID'] },
  )
  .refine(
    ({ isEncryption8021XEnabled, primaryEncryption8021XUUID }) =>
      !isEncryption8021XEnabled || isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    { message: 'Primary RADIUS profile required', path: ['primaryEncryption8021XUUID'] },
  );

export type ValidSwitchProfileParams = z.infer<typeof updateSwitchProfileInputSchema>;

export function useSwitchQueryVariables(uuid: string) {
  const getAccessLevel = useGetAccessLevelForUser();

  return useMemo(
    () => ({
      uuid,
      includeNOSUpgradeGroup: !!getAccessLevel({ permissions: PermissionType.PermNosRead }),
    }),
    [uuid, getAccessLevel],
  );
}

export const SwitchQuery = graphql(`
  query SwitchQuery($uuid: UUID!, $includeNOSUpgradeGroup: Boolean = false) {
    virtualDevice(UUID: $uuid) {
      __typename
      UUID
      label
      description
      networkUUID
      isConsoleEnabled
      deviceType
      deviceModel
      nosUpgradeGroup @include(if: $includeNOSUpgradeGroup) {
        UUID
      }
      nosVersion {
        id
        buildName
        version
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on SwitchVirtualDevice {
        switchProfile {
          UUID
          stpBridgePriority
          mtu
          isFLIPEnabled
          isVoiceVLANEnabled
          voiceVLANUUID
          encryption8021Xs {
            UUID
          }
          encryption8021XGuestVLAN {
            UUID
          }
        }
        outletInterfaceConnectedTo {
          outletNumber
          virtualDevice {
            UUID
            label
          }
        }
        phyInterfaces {
          isFLIPEnabled
          isVoiceVLANEnabled
          UUID
          isEthernet
          isSFP
          isUplink
          isConnected
          portSpeedMbps
          maxSpeedMbps
        }
      }

      hardwareDevice {
        __typename
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        macAddress
        deviceModel
        serialNumber
        deviceType
        bootHistory(count: 1) {
          buildName
        }

        ... on SwitchHardwareDevice {
          ipAddress
        }
      }
    }
  }
`);

export const SwitchesQuery = graphql(`
  query SwitchesQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {
      __typename
      UUID
      uptime
      label
      description
      deviceType
      deviceModel
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on SwitchVirtualDevice {
        switchProfile {
          stpBridgePriority
          mtu
          isVoiceVLANEnabled
          isFLIPEnabled
          voiceVLANUUID
        }

        phyInterfaces {
          UUID
          isEthernet
          isSFP
          isUplink
          isConnected
          portSpeedMbps
          maxSpeedMbps
          isFLIPEnabled
          isVoiceVLANEnabled
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }
        isDev @include(if: $includeIsDev)

        ... on SwitchHardwareDevice {
          stpInfo {
            isRootBridge
            rootBridgePortNumber
          }
          ipAddress
          isInCurrentControllerMACTable
          uptime
        }
      }
    }
  }
`);

export const PortsQuery = graphql(`
  query PortsQuery($virtualDeviceUUID: UUID!) {
    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      UUID
      virtualDeviceUUID
      description
      forcedPortSpeedMbps
      isBoundToAllVLANs
      isEnabled
      isPOEEnabled
      isSTPEdgePortEnabled
      isSTPEnabled
      isTrunkPort
      isUplink
      isConnected
      isLoopbackDetected
      isBlockedDueToFlaps
      isSTPForwarding
      isIngressFilteringEnabled
      frameAcceptTypeFilter
      label
      portNumber
      isEthernet
      isSFP
      powerDraw
      portSpeedMbps
      maxSpeedMbps
      stpPortRole
      stpPathCost
      stpPriority
      isStormControlEnabled
      stormControlBroadcastTrafficPercent
      stormControlUnknownMulticastTrafficPercent
      stormControlUnknownUnicastTrafficPercent
      txRateLimitKbps
      rxRateLimitKbps
      isIsolationEnabled
      maxMACAddressesAllowed
      isSTPRootGuardEnabled
      isFLIPEnabled
      isVoiceVLANEnabled
      encryption8021XSettings {
        isAssignVLANsFromRADIUSEnabled
        isGuestVLANEnabled
        macAuthenticationBypassMode
        portMode
        quietPeriodSeconds
        supplicantPeriodSeconds
      }
      allowedVLANs {
        UUID
        name
        vlanID
      }
      nativeVLAN {
        UUID
        name
        vlanID
      }
      throughputLastDay {
        direction
        value
      }
      connectedDevices {
        macAddress
        hardwareDevice {
          serialNumber
          isConnectedToBackend
          isActive
          deviceType
          virtualDevice {
            __typename
            deviceType
            UUID
            label
          }
        }
      }
      sfpModuleInfo {
        vendor
        serialNumber
        partName
        moduleSpeed
        moduleType
      }
    }
  }
`);

export const MACTableQuery = graphql(`
  query MACTableQuery($virtualDeviceUUID: UUID!) {
    switchMACTable(virtualDeviceUUID: $virtualDeviceUUID) {
      updatedAt
      macAddress
      port
      vlan {
        UUID
        name
        vlanID
      }
    }
  }
`);

export const UpdateSwitchProfileMutation = graphql(`
  mutation UpdateSwitchProfile($uuid: UUID!, $input: UpdateSwitchProfileInput!) {
    updateSwitchProfile(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const FindMACOnSwitchQuery = graphql(`
  query FindMACOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {
    findSwitchesForClientMAC(networkUUID: $networkUUID, macAddress: $mac) {
      virtualDevice {
        UUID
        label
        hardwareDevice {
          isConnectedToBackend
        }
      }
      phyInterface {
        UUID
        portNumber
      }
    }
  }
`);

export const FindLLDPEntryOnSwitchQuery = graphql(`
  query FindLLDPEntryOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {
    findSwitchLLDPEntryForMAC(networkUUID: $networkUUID, macAddress: $mac) {
      virtualDevice {
        UUID
        label
        hardwareDevice {
          isConnectedToBackend
        }
      }
      phyInterface {
        UUID
        portNumber
      }
    }
  }
`);

export const PortCycleMutation = graphql(`
  mutation PortCycleMutation(
    $portNumber: Int!
    $serialNumber: String!
    $poeCycle: Boolean!
    $portCycle: Boolean!
  ) {
    rpcSwitchPortCycle(
      input: {
        portNumber: $portNumber
        serialNumber: $serialNumber
        poeCycle: $poeCycle
        portCycle: $portCycle
      }
    )
  }
`);

export const DeadMansSnitchQuery = graphql(`
  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      token
    }
  }
`);

export function portPowerDraw(port: PhyInterfaceQueryResult) {
  if (!port.isPOEEnabled) return 0;
  if (port.powerDraw && port.powerDraw >= 0.005) return port.powerDraw;
  return 0;
}

export function portMaxSpeed(port: Pick<PhyInterfaceQueryResult, 'isSFP' | 'maxSpeedMbps'>) {
  if (port.maxSpeedMbps) return port.maxSpeedMbps;
  return port.isSFP ? 10000 : 1000;
}

export function isPortSlow(
  port: Pick<PhyInterfaceQueryResult, 'isSFP' | 'portSpeedMbps' | 'maxSpeedMbps'>,
): boolean {
  const max = portMaxSpeed(port);
  if (port.portSpeedMbps === null || port.portSpeedMbps === undefined) {
    return false;
  }
  return port.portSpeedMbps < max;
}

export function portStatus(port: PhyInterfaceQueryResult): 'connected' | 'disconnected' | 'error' {
  if (port.isLoopbackDetected || port.isBlockedDueToFlaps) return 'error';
  return port.isConnected ? 'connected' : 'disconnected';
}

export function isPortConnectedToMeterDevice(
  port: Pick<PhyInterfaceQueryResult, 'connectedDevices'>,
): boolean {
  return !!port.connectedDevices?.[0]?.hardwareDevice;
}

export function portSpeedVariant(
  port: Pick<
    PhyInterfaceQueryResult,
    'isSFP' | 'portSpeedMbps' | 'maxSpeedMbps' | 'connectedDevices'
  >,
): 'negative' | 'attention' | undefined {
  if (isPortSlow(port)) {
    return isPortConnectedToMeterDevice(port) ? 'negative' : 'attention';
  }
  return undefined;
}

export function isPortBlocking(port: PhyInterfaceQueryResult) {
  if (port.isLoopbackDetected || port.isBlockedDueToFlaps) return true;
  return (port.portSpeedMbps ?? 0) > 0 && !port.isSTPForwarding;
}

export function portSpeedForAtto(
  port: Pick<PhyInterfaceQueryResult, 'portSpeedMbps'>,
): PortPropSpeed | undefined {
  switch (port.portSpeedMbps) {
    case 10:
    case 100:
    case 1000:
    case 2500:
    case 10000:
      return port.portSpeedMbps;
  }
  return undefined;
}

export function portSpeedToLabel(speedMbps: number) {
  if (speedMbps >= 1000) {
    const speedGbps = speedMbps / 1000;
    if (Number.isInteger(speedGbps)) {
      return `${speedGbps} Gbps`;
    }

    const fixedGbps = speedGbps.toFixed(2);
    const trimmedGbps = fixedGbps.replace(/0+$/, '').replace(/\.$/, '');
    return `${trimmedGbps} Gbps`;
  }

  return `${speedMbps} Mbps`;
}

export function portThroughput(port: PhyInterfaceQueryResult, direction: 'RX' | 'TX') {
  if (!port.throughputLastDay) return 0;

  const val = port.throughputLastDay.find((tld) => tld.direction === direction);
  if (!val) return 0;

  const gb = Measure.of(val.value, bytes).over(gigabytes).value;

  // Purely for display, we don't want `0.00` to show up in the frontend.
  if (gb < 0.005) return 0;

  return gb;
}

export function portConnection(
  port: PhyInterfaceQueryResult,
  truncateLabel = true,
  handleOnClick?: (port: PhyInterfaceQueryResult) => any,
  checkActive?: (port: PhyInterfaceQueryResult) => boolean,
): {
  hardware: HardwareIconName;
  label: string;
  onClick: (event: void) => any;
  active: boolean;
} | null {
  const connectedDevices = port.connectedDevices ?? [];
  if (connectedDevices.length !== 1) return null;

  const { hardwareDevice } = connectedDevices[0];
  if (!hardwareDevice) return null;

  // This is a bit hacky, but we're basically trying to clean up the label a little. If the device
  // has a label, but it is one of our generated labels (i.e. `meter-mw08-c0f1ba` or
  // `mc01710012510701`), we will try to parse it out to show something more meaningful. In this
  // case, it would be `0f1ba`. This is so our ports don't all who a connection of `meter...` as the
  // label gets truncated. If we have a user specified label, we will just let it through.

  const label = hardwareDevice.virtualDevice?.label ?? '';
  const meterDeviceRegex = /^(meter-m[a-z]\d{2}-|m(s|c|w))\S+$/;
  const isMeterLabel = meterDeviceRegex.test(label);

  return {
    hardware: deviceTypeToHardwareIconPropHardware(hardwareDevice.deviceType),
    label: isMeterLabel && truncateLabel ? label.slice(-4) : label,
    onClick: () => {
      if (handleOnClick) handleOnClick(port);
    },
    active: checkActive ? checkActive(port) : false,
  };
}

const PortNamePlaceholder = styled(Body, {
  opacity: 0.65,
});

export function PortName({ port }: { port: PhyInterfaceQueryResult }) {
  if (port.label) {
    return <span style={{ color: 'currentColor' }}>{port.label}</span>;
  }

  return (
    <PortNamePlaceholder
      style={{ color: 'currentColor' }}
    >{`Port ${port.portNumber}`}</PortNamePlaceholder>
  );
}

export function SwitchSTPInfo({ virtualDevice }: { virtualDevice: SwitchesQueryResult }) {
  if (
    virtualDevice.hardwareDevice &&
    virtualDevice.hardwareDevice.__typename !== 'SwitchHardwareDevice'
  )
    return null;
  if (virtualDevice.__typename !== 'SwitchVirtualDevice') return null;

  const isRootBridge = virtualDevice.hardwareDevice?.stpInfo?.isRootBridge ?? false;

  return (
    <HStack spacing={6} align="center">
      {isRootBridge && (
        <Badge variant="neutral" size="small">
          Root
        </Badge>
      )}
      {virtualDevice.switchProfile.stpBridgePriority}
    </HStack>
  );
}

export function getConnectionTo(
  connectedDevice: NonNullable<PhyInterfaceQueryResult['connectedDevices']>[number],
  {
    networkSlug,
    companyName,
    controllerSerialNumber,
  }: {
    networkSlug: string;
    companyName: string;
    controllerSerialNumber: string | undefined;
  },
): To | undefined {
  const { hardwareDevice } = connectedDevice;

  if (hardwareDevice?.virtualDevice?.deviceType === VirtualDeviceType.Controller) {
    return makeDrawerLink(window.location, paths.drawers.SecurityApplianceSummaryPage, {
      companyName,
      networkSlug,
      uuid: hardwareDevice?.virtualDevice?.UUID,
    });
  }

  if (hardwareDevice?.virtualDevice?.deviceType === VirtualDeviceType.Switch) {
    return makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
      companyName,
      networkSlug,
      uuid: hardwareDevice?.virtualDevice?.UUID,
    });
  }

  if (
    controllerSerialNumber &&
    hardwareDevice?.virtualDevice?.deviceType === VirtualDeviceType.AccessPoint
  ) {
    return makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
      companyName,
      networkSlug,
      uuid: hardwareDevice?.virtualDevice.UUID,
      tab: ValidAPDrawerTab.AccessPoint,
    });
  }

  return undefined;
}

type MACTable = MacTableQueryQuery['switchMACTable'];

/**
 * Sorting value for Connected column. Logic should match `PortDevice` below.
 */
export function portConnectedValue({
  port,
  macTable,
}: {
  port: PhyInterfaceQueryResult;
  macTable?: MACTable;
}): string | undefined {
  const meterConnection = portConnection(port, false);

  if (meterConnection) {
    return `${meterConnection.hardware} ${meterConnection.label}`;
  }

  const devices = port.connectedDevices ?? [];

  if (devices.length === 0) {
    const portEntries = macTable?.filter((entry) => entry.port === port.portNumber) ?? [];

    if (portEntries.length === 1) {
      const portEntry = portEntries[0];
      return portEntry.macAddress;
    }

    return undefined;
  }

  if (devices.length === 1) {
    return devices[0].macAddress;
  }

  return devices.length.toString();
}

export function useConnectedDeviceLink({
  macAddress,
  deviceType,
  uuid,
}: {
  macAddress: string;
  deviceType?: VirtualDeviceType;
  uuid?: string;
  isOnline?: boolean;
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();

  const clients = useNetworkClients(network, {
    filter: {
      lookbackMinutes: 60, // arbitrary
      excludeMeterHardware: false,
    },
  });

  const clientMACSet = useMemo(
    () => new Set(clients.map((client) => client.macAddress)),
    [clients],
  );

  const to: To | undefined = useMemo(() => {
    if (
      (deviceType === VirtualDeviceType.AccessPoint && uuid) ||
      (deviceType === VirtualDeviceType.Switch && uuid) ||
      (deviceType === VirtualDeviceType.Controller && uuid)
    ) {
      return getDeviceDrawerLink({
        uuid,
        deviceType,
        companySlug: companyName,
        networkSlug: network.slug,
      });
    }

    if (clientMACSet.has(macAddress)) {
      return makeDrawerLink(window.location, paths.drawers.ClientSummaryPage, {
        companyName,
        networkSlug: network.slug,
        macAddress,
      });
    }

    // Not adding links to WOS1 clients

    return undefined;
  }, [companyName, network.slug, macAddress, clientMACSet, deviceType, uuid]);

  return to;
}

/**
 * Display node for Connected column. Logic should match `portConnectedValue` above.
 */
export function PortDevice({
  port,
  macTable,
}: {
  port: PhyInterfaceQueryResult;
  macTable?: MACTable;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const controllerSerialNumber =
    useActiveControllerForNetwork(network)?.hardwareDevice?.serialNumber;
  const ouiData = useOuiDataMap();
  const meterConnection = portConnection(port, false);
  const devices = port.connectedDevices ?? [];
  const portEntries = macTable?.filter((entry) => entry.port === port.portNumber) ?? [];

  const meterTo =
    meterConnection &&
    port.connectedDevices?.[0] &&
    getConnectionTo(port.connectedDevices[0], {
      companyName,
      networkSlug: network.slug,
      controllerSerialNumber,
    });

  const deviceTo = useConnectedDeviceLink({
    macAddress: port.connectedDevices?.[0]?.macAddress ?? '',
    deviceType: undefined,
  });
  const entryTo = useConnectedDeviceLink({
    macAddress: portEntries?.[0]?.macAddress ?? '',
    deviceType: undefined,
  });
  const targetAs = deviceTo || entryTo ? ReactRouterLink : 'div';

  const overflowAs = ReactRouterLink;
  const overflowTo = makeLink(paths.pages.SwitchDetailPage, {
    companyName,
    networkSlug: network.slug,
    uuid: port.virtualDeviceUUID,
    tab: ValidSwitchTab.MACTable,
  });

  if (meterConnection) {
    return (
      <DeviceTarget
        as={ReactRouterLink}
        to={meterTo ?? ''}
        icon={meterConnection.hardware}
        aria-label={`Open ${meterConnection.label}`}
        wrap="nowrap"
      >
        {meterConnection.label}
      </DeviceTarget>
    );
  }

  if (devices.length > 0 || portEntries.length > 0) {
    const hasDevices = devices.length > 0;
    const targets = hasDevices ? devices : portEntries;
    return (
      <DeviceTargets
        maximumTargets={2}
        overflow={{
          as: overflowAs,
          to: overflowTo,
        }}
        wrap="nowrap"
        targets={targets.map((target) => {
          const manufacturerName = lookupMACAddressOUI(target.macAddress, ouiData);
          return {
            as: targetAs,
            to: hasDevices ? deviceTo : entryTo,
            icon: manufacturerName ? getManufacturerIconName(manufacturerName) : 'unknown',
            label: target.macAddress,
            wrap: 'nowrap',
          };
        })}
      />
    );
  }

  return <NoValue />;
}

/** Switch Insights Utils */

export function getRootBridgeUUID(switches: SwitchesRootQueryResult[]) {
  return (
    switches.find((s) => {
      if (s.hardwareDevice?.__typename !== 'SwitchHardwareDevice') return false;
      return s.hardwareDevice?.stpInfo?.isRootBridge === true;
    })?.UUID ?? null
  );
}

export const switchPortMetricsQuery = graphql(`
  query SwitchPortMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {
    switchPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {
      values {
        timestamp
        portNumber
        dropsPerSecond
        txErrPerSecond
        rxErrPerSecond
        totalRxBytesPerSecond
        totalTxBytesPerSecond
        multicastRxPacketsPerSecond
        multicastTxPacketsPerSecond
        broadcastRxPacketsPerSecond
        broadcastTxPacketsPerSecond
      }
    }
  }
`);

export const SwitchPortStatsQuery = graphql(`
  query switchPortStatsQuery($virtualDeviceUUID: UUID!) {
    switchPortStats(virtualDeviceUUID: $virtualDeviceUUID) {
      portNumber
      totalRxBytes
      totalTxBytes
    }
  }
`);

export function mergeStatsAndPhyInterfaces(
  stats: SwitchPortStatsQueryQuery | undefined,
  phyInterfaces: PortsQueryQuery | undefined,
  useStat: Boolean,
) {
  if (!useStat) return;
  phyInterfaces?.phyInterfacesForVirtualDevice.map((phyInterface) => {
    const portStats = stats?.switchPortStats.find((s) => s.portNumber === phyInterface.portNumber);
    const mergedPort = phyInterface;
    mergedPort.throughputLastDay = [
      {
        direction: TrafficDirection.Rx,
        value: portStats?.totalRxBytes || 0,
      },
      {
        direction: TrafficDirection.Tx,
        value: portStats?.totalTxBytes || 0,
      },
    ];
    return {
      mergedPort,
    };
  });
}

export type SwitchVirtualDevice = SwitchQueryQuery['virtualDevice'];

export const SwitchPortNumbersQuery = graphql(`
  query SwitchPortNumbers($virtualDeviceUUID: UUID!) {
    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      portNumber
    }
  }
`);

export function useOnConnectionClick() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();
  const activeController = useActiveControllerForNetwork(network);
  const controllerSerialNumber = activeController?.hardwareDevice?.serialNumber;

  return useCallback(
    (port: Pick<PhyInterfaceQueryResult, 'connectedDevices'>) => {
      if (port.connectedDevices && port.connectedDevices.length > 0) {
        const to = getConnectionTo(port.connectedDevices[0], {
          networkSlug: network.slug,
          controllerSerialNumber,
          companyName,
        });
        if (to) return navigate(to);
      }

      return null;
    },
    [network.slug, companyName, controllerSerialNumber, navigate],
  );
}

export function openPortsCount(
  virtualDevice:
    | NonNullable<RackElevationDevice['virtualDevice']>
    | SwitchesQueryResult
    | ControllersForSecurityApplianceQueryQuery['virtualDevicesForNetwork'][number],
): number | undefined {
  // A "24 port" switch has 24 ethernet ports and some number of SFP ports,
  // but we also have switches with no ethernet ports - for those we should
  // count the SFP ports.
  if (!virtualDevice || !('phyInterfaces' in virtualDevice)) return undefined;
  return virtualDevice.phyInterfaces.reduce((acc, p) => (p.isConnected ? acc : acc + 1), 0);
}

// Using return type to check for exhaustiveness
// eslint-disable-next-line consistent-return
export function macBypassAuthenticationModeToHuman(
  macBypassAuthenticationMode: PhyInterface8021XmacAuthenticationBypassMode,
): string {
  switch (macBypassAuthenticationMode) {
    case PhyInterface8021XmacAuthenticationBypassMode.Disabled:
      return 'Disabled';
    case PhyInterface8021XmacAuthenticationBypassMode.Hybrid:
      return 'Hybrid';
    case PhyInterface8021XmacAuthenticationBypassMode.MacAuthOnly:
      return 'MAC auth';
  }
}
