import {
  Body,
  Button,
  colors,
  darkThemeSelector,
  Heading,
  HStack,
  Pane,
  PaneContent,
  PaneFooter,
  PaneHeader,
  Small,
  styled,
  VStack,
} from '@meterup/atto';
import React, { useState } from 'react';

import type { ErrorFallbackProps } from './utils';

const ErrorHeading = styled(Heading, {
  color: colors.red800,
  fontSize: '$20',
  lineHeight: '$24',
});
ErrorHeading.displayName = 'ErrorHeading';

const CodeBlock = styled('pre', Body, {
  padding: '$16',
  backgroundColor: colors.gray50,
  overflow: 'auto',
  borderRadius: '$4',
  vStack: '$8',
  alignItems: 'flex-start',
  [darkThemeSelector]: {
    backgroundColor: colors.gray800,
  },
});
CodeBlock.displayName = 'CodeBlock';

const HeadingWrapper = styled(VStack, {
  paddingY: '$8',
});
HeadingWrapper.displayName = 'HeadingWrapper';

export function DevelopmentErrorFallback({
  error,
  componentStack,
  resetError,
}: ErrorFallbackProps) {
  const [currentStack, setCurrentStack] = useState<'error' | 'component'>('error');
  return (
    <Pane>
      <PaneHeader
        heading={
          <HeadingWrapper spacing={8}>
            <HStack spacing={4} align="baseline">
              <ErrorHeading>Uncaught error:</ErrorHeading>
              <ErrorHeading family="monospace">{error.name}</ErrorHeading>
            </HStack>
            <HStack spacing={4}>
              <Heading size="14">Message:</Heading>
              <Heading size="14" family="monospace">
                {error.message}
              </Heading>
            </HStack>
            <Button type="button" onClick={resetError} variant="secondary">
              Reset error
            </Button>
          </HeadingWrapper>
        }
        tabs={[
          {
            selected: currentStack === 'error',
            onClick: () => setCurrentStack('error'),
            label: 'Stack trace',
          },
          {
            selected: currentStack === 'component',
            onClick: () => setCurrentStack('component'),
            label: 'Component stack',
          },
        ]}
      />
      <PaneContent>
        {currentStack === 'error' ? (
          <CodeBlock family="monospace">{error.stack}</CodeBlock>
        ) : (
          <CodeBlock family="monospace">{componentStack}</CodeBlock>
        )}
      </PaneContent>
      <PaneFooter
        actions={<Small> This error would be logged to Sentry in staging or production.</Small>}
      />
    </Pane>
  );
}
