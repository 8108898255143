import type { z } from 'zod';
import { useMemo } from 'react';

import type { UpdateOutletInterfaceInputSchema } from '../../../gql/zod-types';
import { graphql } from '../../../gql';
import {
  type PowerDistributionUnitQueryQuery,
  type PowerDistributionUnitsQueryQuery,
  PermissionType,
} from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useGetAccessLevelForUser } from '../../HasAccess/useAccessLevelForUser';

export enum ValidPDUsTab {
  Current = 'list',
  Spares = 'spares',
}

export enum ValidPDUTab {
  Insights = 'insights',
  Outlets = 'outlets',
  BootHistory = 'boot-history',
}

export const fallbackPowerDistributionUnitHardwareDeviceQuery = graphql(`
  query FallbackPowerDistributionUnitHardwareQuery($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      virtualDeviceUUID
    }
  }
`);

graphql(`
  fragment OutletInterfaceFields on OutletInterface {
    UUID
    label
    outletNumber
    virtualDeviceUUID
    isEnabled
    powerCycleDelaySec
    description
    currentAmps
    powerWatts
    connectedVirtualDevice {
      UUID
      label
      deviceType
    }
  }
`);

export function usePowerDistributionUnitQueryVariables(uuid: string) {
  const getAccessLevel = useGetAccessLevelForUser();
  return useMemo(
    () => ({
      uuid,
      includeNOSUpgradeGroup: !!getAccessLevel({ permissions: PermissionType.PermNosRead }),
    }),
    [uuid, getAccessLevel],
  );
}

export const PowerDistributionUnitQuery = graphql(`
  query PowerDistributionUnitQuery($uuid: UUID!, $includeNOSUpgradeGroup: Boolean = false) {
    virtualDevice(UUID: $uuid) {
      __typename
      UUID
      label
      description
      networkUUID
      isConsoleEnabled
      deviceType
      deviceModel
      nosUpgradeGroup @include(if: $includeNOSUpgradeGroup) {
        UUID
      }
      nosVersion {
        id
        buildName
        version
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }
      uptime

      ... on PowerDistributionUnitVirtualDevice {
        ipAddress
        ethernetSpeedMbps
        isCircuitBreakerGood
        isGrounded
        isSurgeProtectionGood

        outletInterfaces {
          ...OutletInterfaceFields
        }
      }

      hardwareDevice {
        __typename
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        macAddress
        deviceModel
        serialNumber
        deviceType
        bootHistory(count: 20) {
          bootCount
          buildName
          rebootReason
          createdAt
        }
      }
    }
  }
`);

export type PowerDistributionUnit = PowerDistributionUnitQueryQuery['virtualDevice'] & {
  __typename: 'PowerDistributionUnitVirtualDevice';
};

export type OutletInterface = PowerDistributionUnit['outletInterfaces'][number];

export function usePowerDistributionUnitsQueryVariables() {
  const network = useNetwork();
  const getAccessLevel = useGetAccessLevelForUser();
  return useMemo(
    () => ({
      networkUUID: network.UUID,
      includeIsDev: !!getAccessLevel({
        permissions: PermissionType.PermNetworkDevicesReadRestricted,
      }),
      includeNOSUpgradeGroup: !!getAccessLevel({ permissions: PermissionType.PermNosRead }),
    }),
    [network.UUID, getAccessLevel],
  );
}

export const PowerDistributionUnitsQuery = graphql(`
  query PowerDistributionUnitsQuery(
    $networkUUID: UUID!
    $includeIsDev: Boolean = false
    $includeNOSUpgradeGroup: Boolean = false
  ) {
    virtualDevicesForNetwork(
      networkUUID: $networkUUID
      filter: { deviceType: POWER_DISTRIBUTION_UNIT }
    ) {
      __typename
      UUID
      label
      description
      deviceType
      deviceModel
      nosUpgradeGroup @include(if: $includeNOSUpgradeGroup) {
        UUID
      }
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }
      uptime

      ... on PowerDistributionUnitVirtualDevice {
        ipAddress
        ethernetSpeedMbps
        isCircuitBreakerGood
        isGrounded
        isSurgeProtectionGood

        outletInterfaces {
          ...OutletInterfaceFields
        }
      }

      hardwareDevice {
        __typename
        deviceType
        deviceModel
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }
        isDev @include(if: $includeIsDev)
      }
    }
  }
`);

export type PowerDistributionUnitsQueryResult =
  PowerDistributionUnitsQueryQuery['virtualDevicesForNetwork'][number] & {
    __typename: 'PowerDistributionUnitVirtualDevice';
  };

export const DeadMansSnitchQuery = graphql(`
  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      token
    }
  }
`);

export type UpdateOutletInterfaceFormValues = z.input<typeof UpdateOutletInterfaceInputSchema>;

export const updateOutletInterfaceMutation = graphql(`
  mutation UpdateOutletInterfaceMutation($uuid: UUID!, $input: UpdateOutletInterfaceInput!) {
    updateOutletInterface(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);
