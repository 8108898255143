/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define,@typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define */
import { z } from 'zod';

import type {
  ActiveClientsInput,
  AddNetworkToAutoVpnGroupInput,
  AlertAuditLogFilter,
  AlertTargetInput,
  AllClientMetricsTimeseriesFilterInput,
  AnomalyStatsFilterInput,
  AssignHardwareDeviceToVirtualDeviceInput,
  AttachDeviceToRackElevationInput,
  AutoChannelSelectionJobInput,
  AutoWifiObserverFiltersInput,
  AutoWifiObserverSsidFilter,
  BindFirewallRuleToPhyInterface,
  BindFirewallRuleToVlan,
  BindPolicyRoutingRuleInput,
  BindPolicyRoutingRulesInput,
  BindVlanToPhyInterfaceInput,
  BulkNosUpgradesFilterInput,
  CaptivePortalDisplaySettingsInput,
  CaptivePortalSettingsInput,
  ChannelUtilizationTimeseriesFilterInput,
  CheckRoleInput,
  ClientMetricsTimeseriesFilterInput,
  ConfigureSsoInput,
  CpEsFilterInput,
  CreateAddressInput,
  CreateAlertReceiverWithTargetsInput,
  CreateAlertTargetWebhookInput,
  CreateApplicationDnsFirewallRuleInput,
  CreateAutoVpnGroupHubUplinkInput,
  CreateAutoVpnGroupInput,
  CreateAutoVpnRouteInput,
  CreateAutoWifiObserverSettingInput,
  CreateBulkNosUpgradeInput,
  CreateClientVpnClientInput,
  CreateClientVpnServerInput,
  CreateCompanyInput,
  CreateCompanyUserInput,
  CreateDevHardwareDeviceInput,
  CreateDeviceHeartbeatInput,
  CreateDhcpOptionInput,
  CreateDhcpReservedRangeInput,
  CreateDhcpRuleFilterInput,
  CreateDhcpRuleInput,
  CreateDhcpStaticMappingInput,
  CreateDnsHostMappingInput,
  CreateEncryption8021XInput,
  CreateFirewallRuleInput,
  CreateFloorPlanInput,
  CreateHighAvailabilityPairInput,
  CreateHostMonitoringTargetInput,
  CreateIdsipsConfigInput,
  CreateInternalNoteInput,
  CreateInternetServicePlanInput,
  CreateIpSecTunnelInput,
  CreateMailingAddressInput,
  CreateMarketingChangelogEntryInput,
  CreateNetworkClientCpeBindingInput,
  CreateNetworkInput,
  CreateNetworkThreatAllowlistEntryInput,
  CreateNosFeatureInput,
  CreateNosUpgradeGroupInput,
  CreateNotificationSettingInput,
  CreateOneToOneNatRuleInput,
  CreatePermittedEmailDomainInput,
  CreatePolicyRoutingRuleInput,
  CreatePortForwardingRuleInput,
  CreatePreSignedUploadUrlInput,
  CreateRackElevationInput,
  CreateRadioProfileInput,
  CreateRadioSettingsInput,
  CreateRateLimitRuleInput,
  CreateSsidInput,
  CreateSsoVerifyInput,
  CreateStaticRouteInput,
  CreateSwitchAutoConfigMacAddressVlanBindingInput,
  CreateSwitchProfileInput,
  CreateUserNetworkAccessInput,
  CreateVirtualDeviceInput,
  CreateVirtualDevicesInput,
  CreateVirtualDeviceTagInput,
  CreateVlanInput,
  CreateWifiIpskInput,
  CreateWifiObserverSettingInput,
  CvEsByNetworkMacAddressesInput,
  DeleteDeviceHeartbeatInput,
  DeletePermittedEmailDomainInput,
  DeleteWifiObserverSettingInput,
  DevicesForNetworkFilter,
  DiscoveredServicesInput,
  DownloadUrlInput,
  GetCaptivePortalInput,
  GetCompanyUserInput,
  GetNotificationPreferencesInput,
  HardwareDevicesFilter,
  InternetServicePlanConnectionInput,
  InternetServicePlanIpInput,
  MetricsFilterInput,
  MetricsSerialNumberDirectionInput,
  MintTokenInput,
  MutationAuditLogFilter,
  MutationDiffAuditLogFilterInput,
  NetworkClientsFilter,
  NetworksBooleanFilter,
  NetworksCompanySupportTierFilter,
  NetworksDateTimeFilter,
  NetworksFilter,
  NetworksHardwareModelFilter,
  NetworksIntFilter,
  NetworksNosVersionIdFilter,
  NetworkStatsFilter,
  NetworkThreatsFilterInput,
  NosVersionInput,
  NotificationPreferenceInput,
  NotificationSettingArgumentInput,
  PermissionsInput,
  PolicyRoutingRuleBindingInput,
  PortRangeInput,
  RackElevationNoteInput,
  RateLimitPhyInterfaceBindingInput,
  RateLimitVlanBindingInput,
  RolesInput,
  RpcEapolTestForSerialInput,
  RpcEapolTestForSerialsInput,
  RpcpduOutletCycleInput,
  RpcPingInput,
  RpcSwitchPortCycleInput,
  RpcwanSpeedtestInput,
  RpcWosObserverInput,
  RpcWosPacketCaptureRadioParametersInput,
  RpcWosPacketCaptureStartInput,
  RpcWosPacketCaptureStopInput,
  SetNetworkNosVersionInput,
  SetPolicyRoutingRuleBindingsInput,
  SsidBroadcastScheduleIntervalInput,
  SsoOptionsInput,
  StartWanSpeedtestJobInput,
  StatInjectionDuration,
  StatsFilter,
  SwitchAutoConfigurationJobInput,
  SystemInfoStatsFilter,
  TestTriggerAlertTargetWebhookInput,
  TwilioCellularActivationInput,
  UnbindFirewallRuleFromPhyInterface,
  UnbindFirewallRuleFromVlan,
  UnbindPolicyRoutingRuleInput,
  UpdateAlertReceiverWithTargetsInput,
  UpdateAlertTargetWebhookInput,
  UpdateApplicationDnsFirewallRuleApplicationVisibilities,
  UpdateApplicationDnsFirewallRuleInput,
  UpdateApplicationDnsFirewallRulesInput,
  UpdateApplicationDnsFirewallRulesPrioritiesInput,
  UpdateAutoVpnGroupInput,
  UpdateAutoVpnMemberInput,
  UpdateAutoVpnRouteInput,
  UpdateClientVpnClientInput,
  UpdateClientVpnServerInput,
  UpdateCompanyInput,
  UpdateCompanyUserInput,
  UpdateDefaultRoleForUsersInput,
  UpdateDhcpOptionInput,
  UpdateDhcpReservedRangeInput,
  UpdateDhcpReservedRangeUuidInput,
  UpdateDhcpRuleInput,
  UpdateDhcpStaticMappingInput,
  UpdateDhcpStaticMappingUuidInput,
  UpdateDnsHostMappingInput,
  UpdateEncryption8021XInput,
  UpdateFirewallRuleInput,
  UpdateFirewallRulesInput,
  UpdateFloorPlanInput,
  UpdateForCellularFailoverEventInput,
  UpdateForHighAvailabilityEventInput,
  UpdateHardwareDeviceInput,
  UpdateHighAvailabilityPairInput,
  UpdateHostMonitoringTargetInput,
  UpdateIdsipsConfigInput,
  UpdateInternalNoteInput,
  UpdateInternetServicePlanInput,
  UpdateIpSecTunnelInput,
  UpdateMailingAddressInput,
  UpdateMaintenanceWindowInput,
  UpdateMarketingChangelogEntryInput,
  UpdateNetworkInput,
  UpdateNetworkThreatAllowlistEntryInput,
  UpdateNosFeatureInput,
  UpdateNosUpgradeGroupInput,
  UpdateNotificationSettingInput,
  UpdateOneToOneNatRuleInput,
  UpdateOutletInterfaceInput,
  UpdatePhyInterface8021XSettingsInput,
  UpdatePhyInterfaceForMultiWanEventInput,
  UpdatePhyInterfaceInput,
  UpdatePolicyRoutingRuleInput,
  UpdatePortForwardingRuleInput,
  UpdateRackElevationDeviceInput,
  UpdateRackElevationInput,
  UpdateRadioProfileInput,
  UpdateRadioSettingsInput,
  UpdateRateLimitRuleInput,
  UpdateSecurityApplianceProfileInput,
  UpdateSsidInput,
  UpdateStaticRouteInput,
  UpdateSwitchProfileInput,
  UpdateUplinkPhyInterfaceExternalAddressesInput,
  UpdateVirtualDeviceIndependentlyInput,
  UpdateVirtualDeviceInput,
  UpdateVirtualDeviceTagInput,
  UpdateVlandhcpRuleInput,
  UpdateVlanInput,
  UpdateVlaNsIndependentlyInput,
  UpdateVlaNsInput,
  UpdateWifiIpskInput,
  UpsertNetworkOnboardingFieldDelegationInput,
  UserRoleInput,
  VlanPairInput,
  WosObserverCommandInput,
} from './graphql';
import {
  AccessLevel,
  AlertParameterType,
  AlertTargetType,
  AllowedFolder,
  AutoChannelSelectionResultType,
  CellularFailoverEventRoute,
  CellularManagerModemState,
  ChannelWidth,
  ClientAssignmentProtocol,
  CompanyMembershipRole,
  CompanySupportTier,
  CveSeverity,
  DeviceModel,
  DeviceType,
  DhcpOptionDataType,
  EventType,
  FirewallRuleAction,
  FirmwareUpgradeCompletedStatus,
  HighAvailabilityControllerRole,
  HighAvailabilityStatus,
  HostMonitoringStatus,
  HostMonitoringType,
  IdsipsMode,
  ImageType,
  IncidentCategory,
  IpProtocol,
  IpSecAuthenticationAlgorithm,
  IpSecChildSaStatus,
  IpSecDhGroup,
  IpSecEncryptionAlgorithm,
  IpSecPeerType,
  IpSecSaStatus,
  IspProduct,
  IspStatus,
  MaintenanceWindowRecurrence,
  ManagementConnectionType,
  MultiWanAlgorithm,
  NetworkClientHwMode,
  NetworkEventType,
  NosUpgradeGroupDeviceSubtype,
  Permission,
  PermissionType,
  PhyInterface8021XmacAuthenticationBypassMode,
  PhyInterface8021XPortMode,
  PhyInterfaceFrameAcceptTypeFilter,
  PortIpProtocol,
  PortType,
  PrincipalType,
  RackElevationDeviceType,
  RadioBand,
  Role,
  RoleName,
  RpcCableTestTwistedPairStatus,
  RpcEapolTestForSerialStatus,
  RpcWosObserverResponseStatus,
  RpcWosPacketCaptureResponseStatus,
  ScheduledNosUpgradeStatus,
  ScopeKind,
  ServiceType,
  SsidDynamicVlanMode,
  SsidEncryption80211wProtectedManagementFramesMode,
  SsidEncryptionProtocol,
  SsidPasswordRotationCadence,
  SsoAdminPortalType,
  SsoConnectionState,
  SsoConnectionType,
  SsoDirectoryTypes,
  SsoState,
  SsoVerificationSource,
  StatType,
  StpPortRole,
  SwitchAutoConfigurationResultToggleDirection,
  SwitchAutoConfigurationResultWarning,
  TokenType,
  TrafficDirection,
  TwilioCellularActivationStatus,
  TwilioCellularCountry,
  UnauthenticatedReferer,
  VirtualDeviceType,
  Wan,
  WanSpeedtestDirection,
  WanSpeedtestStatus,
  WebhookAuthType,
  WirelessClientConnectionEventType,
  WirelessClientConnectionFailedStep,
  WosObserverCommandTargetType,
  WosObserverCommandType,
  WosPacketCaptureCommandMonitor,
} from './graphql';

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const WosPacketCaptureCommandMonitorSchema = z.nativeEnum(WosPacketCaptureCommandMonitor);

export const WosObserverCommandTypeSchema = z.nativeEnum(WosObserverCommandType);

export const WosObserverCommandTargetTypeSchema = z.nativeEnum(WosObserverCommandTargetType);

export const WirelessClientConnectionEventTypeSchema = z.nativeEnum(
  WirelessClientConnectionEventType,
);

export const WirelessClientConnectionFailedStepSchema = z.nativeEnum(
  WirelessClientConnectionFailedStep,
);

export const WanSpeedtestStatusSchema = z.nativeEnum(WanSpeedtestStatus);

export const WanSpeedtestDirectionSchema = z.nativeEnum(WanSpeedtestDirection);

export const UnauthenticatedRefererSchema = z.nativeEnum(UnauthenticatedReferer);

export const TwilioCellularCountrySchema = z.nativeEnum(TwilioCellularCountry);

export const TwilioCellularActivationStatusSchema = z.nativeEnum(TwilioCellularActivationStatus);

export const TrafficDirectionSchema = z.nativeEnum(TrafficDirection);

export const TokenTypeSchema = z.nativeEnum(TokenType);

export const SwitchAutoConfigurationResultWarningSchema = z.nativeEnum(
  SwitchAutoConfigurationResultWarning,
);

export const SwitchAutoConfigurationResultToggleDirectionSchema = z.nativeEnum(
  SwitchAutoConfigurationResultToggleDirection,
);

export const StatTypeSchema = z.nativeEnum(StatType);

export const ServiceTypeSchema = z.nativeEnum(ServiceType);

export const ScopeKindSchema = z.nativeEnum(ScopeKind);

export const ScheduledNosUpgradeStatusSchema = z.nativeEnum(ScheduledNosUpgradeStatus);

export const SsoStateSchema = z.nativeEnum(SsoState);

export const SsoDirectoryTypesSchema = z.nativeEnum(SsoDirectoryTypes);

export const SsoConnectionTypeSchema = z.nativeEnum(SsoConnectionType);

export const SsoConnectionStateSchema = z.nativeEnum(SsoConnectionState);

export const SsoAdminPortalTypeSchema = z.nativeEnum(SsoAdminPortalType);

export const RoleSchema = z.nativeEnum(Role);

export const RpcWosPacketCaptureResponseStatusSchema = z.nativeEnum(
  RpcWosPacketCaptureResponseStatus,
);

export const RpcWosObserverResponseStatusSchema = z.nativeEnum(RpcWosObserverResponseStatus);

export const WanSchema = z.nativeEnum(Wan);

export const RpcEapolTestForSerialStatusSchema = z.nativeEnum(RpcEapolTestForSerialStatus);

export const RpcCableTestTwistedPairStatusSchema = z.nativeEnum(RpcCableTestTwistedPairStatus);

export const PrincipalTypeSchema = z.nativeEnum(PrincipalType);

export const PortTypeSchema = z.nativeEnum(PortType);

export const PortIpProtocolSchema = z.nativeEnum(PortIpProtocol);

export const PhyInterface8021XPortModeSchema = z.nativeEnum(PhyInterface8021XPortMode);

export const PhyInterface8021XmacAuthenticationBypassModeSchema = z.nativeEnum(
  PhyInterface8021XmacAuthenticationBypassMode,
);

export const StpPortRoleSchema = z.nativeEnum(StpPortRole);

export const PhyInterfaceFrameAcceptTypeFilterSchema = z.nativeEnum(
  PhyInterfaceFrameAcceptTypeFilter,
);

export const PermissionTypeSchema = z.nativeEnum(PermissionType);

export const PermissionSchema = z.nativeEnum(Permission);

export const NetworkEventTypeSchema = z.nativeEnum(NetworkEventType);

export const RadioBandSchema = z.nativeEnum(RadioBand);

export const NetworkClientHwModeSchema = z.nativeEnum(NetworkClientHwMode);

export const NosUpgradeGroupDeviceSubtypeSchema = z.nativeEnum(NosUpgradeGroupDeviceSubtype);

export const RoleNameSchema = z.nativeEnum(RoleName);

export const ManagementConnectionTypeSchema = z.nativeEnum(ManagementConnectionType);

export const MaintenanceWindowRecurrenceSchema = z.nativeEnum(MaintenanceWindowRecurrence);

export const IncidentCategorySchema = z.nativeEnum(IncidentCategory);

export const ImageTypeSchema = z.nativeEnum(ImageType);

export const IspStatusSchema = z.nativeEnum(IspStatus);

export const IspProductSchema = z.nativeEnum(IspProduct);

export const IpSecSaStatusSchema = z.nativeEnum(IpSecSaStatus);

export const IpSecChildSaStatusSchema = z.nativeEnum(IpSecChildSaStatus);

export const IdsipsModeSchema = z.nativeEnum(IdsipsMode);

export const HostMonitoringTypeSchema = z.nativeEnum(HostMonitoringType);

export const HostMonitoringStatusSchema = z.nativeEnum(HostMonitoringStatus);

export const HighAvailabilityStatusSchema = z.nativeEnum(HighAvailabilityStatus);

export const HighAvailabilityControllerRoleSchema = z.nativeEnum(HighAvailabilityControllerRole);

export const FirmwareUpgradeCompletedStatusSchema = z.nativeEnum(FirmwareUpgradeCompletedStatus);

export const FirewallRuleActionSchema = z.nativeEnum(FirewallRuleAction);

export const EventTypeSchema = z.nativeEnum(EventType);

export const VirtualDeviceTypeSchema = z.nativeEnum(VirtualDeviceType);

export const DeviceTypeSchema = z.nativeEnum(DeviceType);

export const DeviceModelSchema = z.nativeEnum(DeviceModel);

export const SsoVerificationSourceSchema = z.nativeEnum(SsoVerificationSource);

export const SsidPasswordRotationCadenceSchema = z.nativeEnum(SsidPasswordRotationCadence);

export const SsidEncryptionProtocolSchema = z.nativeEnum(SsidEncryptionProtocol);

export const SsidEncryption80211wProtectedManagementFramesModeSchema = z.nativeEnum(
  SsidEncryption80211wProtectedManagementFramesMode,
);

export const SsidDynamicVlanModeSchema = z.nativeEnum(SsidDynamicVlanMode);

export const MultiWanAlgorithmSchema = z.nativeEnum(MultiWanAlgorithm);

export const IpSecPeerTypeSchema = z.nativeEnum(IpSecPeerType);

export const IpSecDhGroupSchema = z.nativeEnum(IpSecDhGroup);

export const IpSecEncryptionAlgorithmSchema = z.nativeEnum(IpSecEncryptionAlgorithm);

export const IpSecAuthenticationAlgorithmSchema = z.nativeEnum(IpSecAuthenticationAlgorithm);

export const IpProtocolSchema = z.nativeEnum(IpProtocol);

export const DhcpOptionDataTypeSchema = z.nativeEnum(DhcpOptionDataType);

export const CompanyMembershipRoleSchema = z.nativeEnum(CompanyMembershipRole);

export const CompanySupportTierSchema = z.nativeEnum(CompanySupportTier);

export const ClientAssignmentProtocolSchema = z.nativeEnum(ClientAssignmentProtocol);

export const ChannelWidthSchema = z.nativeEnum(ChannelWidth);

export const CellularManagerModemStateSchema = z.nativeEnum(CellularManagerModemState);

export const CellularFailoverEventRouteSchema = z.nativeEnum(CellularFailoverEventRoute);

export const CveSeveritySchema = z.nativeEnum(CveSeverity);

export const AutoChannelSelectionResultTypeSchema = z.nativeEnum(AutoChannelSelectionResultType);

export const RackElevationDeviceTypeSchema = z.nativeEnum(RackElevationDeviceType);

export const AllowedFolderSchema = z.nativeEnum(AllowedFolder);

export const WebhookAuthTypeSchema = z.nativeEnum(WebhookAuthType);

export const AlertTargetTypeSchema = z.nativeEnum(AlertTargetType);

export const AlertParameterTypeSchema = z.nativeEnum(AlertParameterType);

export const AccessLevelSchema = z.nativeEnum(AccessLevel);

export const WosObserverCommandInputSchema: z.ZodObject<Properties<WosObserverCommandInput>> =
  z.object({
    commandTargetType: WosObserverCommandTargetTypeSchema,
    commandType: WosObserverCommandTypeSchema,
    index: z.number(),
    intervalMsec: z.number(),
    orderIndex: z.number(),
    target: z.string(),
    timeoutMsec: z.number(),
  });

export const VlanPairInputSchema: z.ZodObject<Properties<VlanPairInput>> = z.object({
  vlan1UUID: z.string(),
  vlan2UUID: z.string(),
});

export const UpsertNetworkOnboardingFieldDelegationInputSchema: z.ZodObject<
  Properties<UpsertNetworkOnboardingFieldDelegationInput>
> = z.object({
  description: z.string().nullish(),
  email: z.string(),
});

export const UpdateVirtualDeviceTagInputSchema: z.ZodObject<
  Properties<UpdateVirtualDeviceTagInput>
> = z.object({
  name: z.string().nullish(),
});

export const UpdateVirtualDeviceIndependentlyInputSchema: z.ZodObject<
  Properties<UpdateVirtualDeviceIndependentlyInput>
> = z.object({
  input: z.lazy(() => UpdateVirtualDeviceInputSchema),
  virtualDeviceUUID: z.string(),
});

export const UpdateVlaNsInputSchema: z.ZodObject<Properties<UpdateVlaNsInput>> = z.object({
  isMulticastReflectionEnabled: z.boolean().nullish(),
});

export const UpdateVlaNsIndependentlyInputSchema: z.ZodObject<
  Properties<UpdateVlaNsIndependentlyInput>
> = z.object({
  UUID: z.string(),
  input: z.lazy(() => UpdateVlaNsInputSchema),
});

export const UpdateVlandhcpRuleInputSchema: z.ZodObject<Properties<UpdateVlandhcpRuleInput>> =
  z.object({
    dhcpRuleUUID: z.string(),
    input: z.lazy(() => UpdateDhcpRuleInputSchema),
  });

export const UpdateUplinkPhyInterfaceExternalAddressesInputSchema: z.ZodObject<
  Properties<UpdateUplinkPhyInterfaceExternalAddressesInput>
> = z.object({
  portNumber: z.number(),
  uplinkExternalAddresses: z.array(z.string()),
  uplinkExternalAddressesUpdatedAt: z.string(),
});

export const UpdateStaticRouteInputSchema: z.ZodObject<Properties<UpdateStaticRouteInput>> =
  z.object({
    isEnabled: z.boolean().nullish(),
    name: z.string().nullish(),
  });

export const UpdateRadioSettingsInputSchema: z.ZodObject<Properties<UpdateRadioSettingsInput>> =
  z.object({
    band2_4GPrimaryChannel: z.number().nullish(),
    band2_4GSecondaryChannel: z.number().nullish(),
    band2_4GTransmitPowerdBm: z.number().nullish(),
    band5GPrimaryChannel: z.number().nullish(),
    band5GSecondaryChannel: z.number().nullish(),
    band5GTransmitPowerdBm: z.number().nullish(),
  });

export const UpdateRadioProfileInputSchema: z.ZodObject<Properties<UpdateRadioProfileInput>> =
  z.object({
    band2_4GAllowedChannels: z.array(z.number()).nullish(),
    band2_4GAutoChannelIsEnabled: z.boolean().nullish(),
    band2_4GAutoChannelWidthIsEnabled: z.boolean().nullish(),
    band2_4GAutoTxPowerIsEnabled: z.boolean().nullish(),
    band2_4GAutoTxPowerMaxdBm: z.number().nullish(),
    band2_4GAutoTxPowerMindBm: z.number().nullish(),
    band2_4GChannelWidthMHz: z.number().nullish(),
    band2_4GIsEnabled: z.boolean().nullish(),
    band5GAllowedChannels: z.array(z.number()).nullish(),
    band5GAutoChannelIsEnabled: z.boolean().nullish(),
    band5GAutoChannelWidthIsEnabled: z.boolean().nullish(),
    band5GAutoTxPowerIsEnabled: z.boolean().nullish(),
    band5GAutoTxPowerMaxdBm: z.number().nullish(),
    band5GAutoTxPowerMindBm: z.number().nullish(),
    band5GChannelWidthMHz: z.number().nullish(),
    band5GIsEnabled: z.boolean().nullish(),
    isDefault: z.boolean().nullish(),
    name: z.string().nullish(),
  });

export const UpdateRackElevationInputSchema: z.ZodObject<Properties<UpdateRackElevationInput>> =
  z.object({
    horizontalIndex: z.number().nullish(),
    label: z.string().nullish(),
    notes: z.array(z.lazy(() => RackElevationNoteInputSchema)).nullish(),
    rackMountUnitCount: z.number().nullish(),
  });

export const UpdatePhyInterfaceForMultiWanEventInputSchema: z.ZodObject<
  Properties<UpdatePhyInterfaceForMultiWanEventInput>
> = z.object({
  activePortNumber: z.number(),
  activePortNumberUpdatedAt: z.string(),
  uplinkExternalAddresses: z.array(z.string()),
});

export const UpdateNosFeatureInputSchema: z.ZodObject<Properties<UpdateNosFeatureInput>> = z.object(
  {
    description: z.string(),
    featureFlag: z.string().nullish(),
    maxMajorVersion: z.number().nullish(),
    maxMinorVersion: z.number().nullish(),
    maxPatchVersion: z.number().nullish(),
    minMajorVersion: z.number(),
    minMinorVersion: z.number(),
    minPatchVersion: z.number(),
    name: z.string(),
  },
);

export const UpdateNosUpgradeGroupInputSchema: z.ZodObject<Properties<UpdateNosUpgradeGroupInput>> =
  z.object({
    description: z.string().nullish(),
    name: z.string().nullish(),
    priority: z.number().nullish(),
    timeoutDurationSeconds: z.number().nullish(),
    virtualDeviceUUIDs: z.array(z.string()).nullish(),
  });

export const UpdateMailingAddressInputSchema: z.ZodObject<Properties<UpdateMailingAddressInput>> =
  z.object({
    city: z.string().nullish(),
    countryISO2: z.string().nullish(),
    line1: z.string().nullish(),
    line2: z.string().nullish(),
    postalCode: z.string().nullish(),
    subdivisionCode: z.string().nullish(),
  });

export const UpdateInternalNoteInputSchema: z.ZodObject<Properties<UpdateInternalNoteInput>> =
  z.object({
    isImportant: z.boolean().nullish(),
    note: z.string().nullish(),
    photoS3Keys: z.array(z.string()).nullish(),
  });

export const UpdateHostMonitoringTargetInputSchema: z.ZodObject<
  Properties<UpdateHostMonitoringTargetInput>
> = z.object({
  description: z.string().nullish(),
  isEnabled: z.boolean().nullish(),
  isUsedForWANFailover: z.boolean().nullish(),
});

export const UpdateHardwareDeviceInputSchema: z.ZodObject<Properties<UpdateHardwareDeviceInput>> =
  z.object({
    active: z.boolean().nullish(),
    consoleEnabled: z.boolean().nullish(),
    factoryMode: z.boolean().nullish(),
    retired: z.boolean().nullish(),
    rma: z.boolean().nullish(),
  });

export const UpdateForHighAvailabilityEventInputSchema: z.ZodObject<
  Properties<UpdateForHighAvailabilityEventInput>
> = z.object({
  status: HighAvailabilityStatusSchema,
});

export const UpdateForCellularFailoverEventInputSchema: z.ZodObject<
  Properties<UpdateForCellularFailoverEventInput>
> = z.object({
  route: CellularFailoverEventRouteSchema,
});

export const UpdateFloorPlanInputSchema: z.ZodObject<Properties<UpdateFloorPlanInput>> = z.object({
  description: z.string().nullish(),
  imageS3Key: z.string().nullish(),
  label: z.string().nullish(),
});

export const UpdateFirewallRulesInputSchema: z.ZodObject<Properties<UpdateFirewallRulesInput>> =
  z.object({
    firewallRuleUUID: z.string(),
    input: z.lazy(() => UpdateFirewallRuleInputSchema),
  });

export const UpdateDefaultRoleForUsersInputSchema: z.ZodObject<
  Properties<UpdateDefaultRoleForUsersInput>
> = z.object({
  defaultRoleForUsers: RoleNameSchema,
});

export const UpdateDhcpStaticMappingUuidInputSchema: z.ZodObject<
  Properties<UpdateDhcpStaticMappingUuidInput>
> = z.object({
  UUID: z.string(),
  input: z.lazy(() => UpdateDhcpStaticMappingInputSchema),
});

export const UpdateDhcpReservedRangeUuidInputSchema: z.ZodObject<
  Properties<UpdateDhcpReservedRangeUuidInput>
> = z.object({
  UUID: z.string(),
  input: z.lazy(() => UpdateDhcpReservedRangeInputSchema),
});

export const UpdateCompanyInputSchema: z.ZodObject<Properties<UpdateCompanyInput>> = z.object({
  isProspective: z.boolean().nullish(),
  name: z.string().nullish(),
  slug: z.string().nullish(),
});

export const UpdateClientVpnClientInputSchema: z.ZodObject<Properties<UpdateClientVpnClientInput>> =
  z.object({
    isEnabled: z.boolean().nullish(),
  });

export const UpdateApplicationDnsFirewallRulesPrioritiesInputSchema: z.ZodObject<
  Properties<UpdateApplicationDnsFirewallRulesPrioritiesInput>
> = z.object({
  applicationDNSFirewallRuleUUID: z.string(),
  priority: z.number(),
});

export const UpdateApplicationDnsFirewallRulesInputSchema: z.ZodObject<
  Properties<UpdateApplicationDnsFirewallRulesInput>
> = z.object({
  applicationDNSFirewallRuleUUID: z.string(),
  input: z.lazy(() => UpdateApplicationDnsFirewallRuleInputSchema),
});

export const UpdateApplicationDnsFirewallRuleApplicationVisibilitiesSchema: z.ZodObject<
  Properties<UpdateApplicationDnsFirewallRuleApplicationVisibilities>
> = z.object({
  id: z.number(),
  isVisible: z.boolean(),
});

export const UpdateAlertReceiverWithTargetsInputSchema: z.ZodObject<
  Properties<UpdateAlertReceiverWithTargetsInput>
> = z.object({
  label: z.string().nullish(),
  targets: z.array(z.lazy(() => AlertTargetInputSchema)).nullish(),
});

export const UnbindPolicyRoutingRuleInputSchema: z.ZodObject<
  Properties<UnbindPolicyRoutingRuleInput>
> = z.object({
  binding: z.lazy(() => PolicyRoutingRuleBindingInputSchema),
});

export const UnbindFirewallRuleFromVlanSchema: z.ZodObject<Properties<UnbindFirewallRuleFromVlan>> =
  z.object({
    firewallRuleUUID: z.string(),
    vlanUUID: z.string(),
  });

export const UnbindFirewallRuleFromPhyInterfaceSchema: z.ZodObject<
  Properties<UnbindFirewallRuleFromPhyInterface>
> = z.object({
  firewallRuleUUID: z.string(),
  phyInterfaceUUID: z.string(),
});

export const TwilioCellularActivationInputSchema: z.ZodObject<
  Properties<TwilioCellularActivationInput>
> = z.object({
  country: TwilioCellularCountrySchema,
  iccid: z.string(),
});

export const TestTriggerAlertTargetWebhookInputSchema: z.ZodObject<
  Properties<TestTriggerAlertTargetWebhookInput>
> = z.object({
  eventName: z.string(),
  payload: z.string(),
});

export const SwitchAutoConfigurationJobInputSchema: z.ZodObject<
  Properties<SwitchAutoConfigurationJobInput>
> = z.object({
  isDryRun: z.boolean().default(true),
  runNow: z.boolean().default(false),
  switchVirtualDeviceUUIDs: z.array(z.string()).nullish(),
  uncategorizedClientNativeVLANUUID: z.string(),
});

export const StatInjectionDurationSchema: z.ZodObject<Properties<StatInjectionDuration>> = z.object(
  {
    endTime: z.string(),
    startTime: z.string(),
  },
);

export const StartWanSpeedtestJobInputSchema: z.ZodObject<Properties<StartWanSpeedtestJobInput>> =
  z.object({
    baseURL: z.string().default('https://speed.cloudflare.com'),
    maxTransferBytes: z.number().nullish(),
    phyInterfaceUUID: z.string(),
  });

export const SetPolicyRoutingRuleBindingsInputSchema: z.ZodObject<
  Properties<SetPolicyRoutingRuleBindingsInput>
> = z.object({
  bindings: z.array(z.lazy(() => PolicyRoutingRuleBindingInputSchema)),
});

export const SetNetworkNosVersionInputSchema: z.ZodObject<Properties<SetNetworkNosVersionInput>> =
  z.object({
    force: z.boolean().default(false),
    nosId: z.number(),
    staggeredUpgradeIntervalMin: z.number().default(0),
  });

export const SsoOptionsInputSchema: z.ZodObject<Properties<SsoOptionsInput>> = z.object({
  companySlug: z.string(),
});

export const SsidBroadcastScheduleIntervalInputSchema: z.ZodObject<
  Properties<SsidBroadcastScheduleIntervalInput>
> = z.object({
  endDayOfWeek: z.number(),
  endHour: z.number(),
  endMinute: z.number(),
  startDayOfWeek: z.number(),
  startHour: z.number(),
  startMinute: z.number(),
});

export const RolesInputSchema: z.ZodObject<Properties<RolesInput>> = z.object({
  companySlug: z.string().nullish(),
});

export const RateLimitVlanBindingInputSchema: z.ZodObject<Properties<RateLimitVlanBindingInput>> =
  z.object({
    rateLimitRuleUUID: z.string(),
    vlanUUID: z.string(),
  });

export const RateLimitPhyInterfaceBindingInputSchema: z.ZodObject<
  Properties<RateLimitPhyInterfaceBindingInput>
> = z.object({
  phyInterfaceUUID: z.string(),
  rateLimitRuleUUID: z.string(),
});

export const RackElevationNoteInputSchema: z.ZodObject<Properties<RackElevationNoteInput>> =
  z.object({
    note: z.string(),
    rackMountUnitIndexEnd: z.number(),
    rackMountUnitIndexStart: z.number(),
  });

export const RpcWosPacketCaptureStopInputSchema: z.ZodObject<
  Properties<RpcWosPacketCaptureStopInput>
> = z.object({
  jobId: z.string(),
});

export const RpcWosPacketCaptureStartInputSchema: z.ZodObject<
  Properties<RpcWosPacketCaptureStartInput>
> = z.object({
  channels: z.array(z.lazy(() => RpcWosPacketCaptureRadioParametersInputSchema)).nullish(),
  listenOn: WosPacketCaptureCommandMonitorSchema,
  onDevice: z.boolean().nullish(),
  sizeLimitMb: z.number(),
  tcpdumpFilter: z.string().nullish(),
  timeLimitSec: z.number(),
});

export const RpcWosPacketCaptureRadioParametersInputSchema: z.ZodObject<
  Properties<RpcWosPacketCaptureRadioParametersInput>
> = z.object({
  band: RadioBandSchema,
  channel: z.number(),
  channelWidth: ChannelWidthSchema,
});

export const RpcSwitchPortCycleInputSchema: z.ZodObject<Properties<RpcSwitchPortCycleInput>> =
  z.object({
    cycleDownSec: z.number().default(3).nullish(),
    poeCycle: z.boolean().nullish(),
    portCycle: z.boolean().nullish(),
    portNumber: z.number(),
    serialNumber: z.string(),
  });

export const RpcpduOutletCycleInputSchema: z.ZodObject<Properties<RpcpduOutletCycleInput>> =
  z.object({
    outletUUIDs: z.array(z.string()),
  });

export const PolicyRoutingRuleBindingInputSchema: z.ZodObject<
  Properties<PolicyRoutingRuleBindingInput>
> = z.object({
  phyInterfaceUUID: z.string(),
});

export const UpdatePhyInterface8021XSettingsInputSchema: z.ZodObject<
  Properties<UpdatePhyInterface8021XSettingsInput>
> = z.object({
  isAssignVLANsFromRADIUSEnabled: z.boolean().nullish(),
  isGuestVLANEnabled: z.boolean().nullish(),
  macAuthenticationBypassMode: PhyInterface8021XmacAuthenticationBypassModeSchema.nullish(),
  portMode: PhyInterface8021XPortModeSchema.nullish(),
  quietPeriodSeconds: z.number().nullish(),
  reauthenticationPeriodSeconds: z.number().nullish(),
  supplicantPeriodSeconds: z.number().nullish(),
});

export const NotificationPreferenceInputSchema: z.ZodObject<
  Properties<NotificationPreferenceInput>
> = z.object({
  additionalEventTypes: z.array(NetworkEventTypeSchema).nullish(),
  additionalSlackChannels: z.array(z.string()).nullish(),
  emailAddresses: z.array(z.string()).nullish(),
});

export const NosVersionInputSchema: z.ZodObject<Properties<NosVersionInput>> = z.object({
  isDefault: z.boolean().nullish(),
  isDeprecated: z.boolean().nullish(),
  isGA: z.boolean().nullish(),
  isLocked: z.boolean().nullish(),
  isValidationEnabled: z.boolean().default(true).nullish(),
  major: z.number().nullish(),
  mc01Build: z.string().nullish(),
  minor: z.number().nullish(),
  mp01Build: z.string().nullish(),
  ms10Build: z.string().nullish(),
  ms11Build: z.string().nullish(),
  ms12Build: z.string().nullish(),
  mw03Build: z.string().nullish(),
  mw04Build: z.string().nullish(),
  mw06Build: z.string().nullish(),
  mw07Build: z.string().nullish(),
  mw08Build: z.string().nullish(),
  mw09Build: z.string().nullish(),
  name: z.string().nullish(),
  observerBuild: z.string().nullish(),
  patch: z.number().nullish(),
  releaseNotes: z.string().nullish(),
  version: z.string(),
});

export const NetworksNosVersionIdFilterSchema: z.ZodObject<Properties<NetworksNosVersionIdFilter>> =
  z.object({
    in: z.array(z.number()),
    not: z.boolean().default(false),
  });

export const NetworksIntFilterSchema: z.ZodObject<Properties<NetworksIntFilter>> = z.object({
  gt: z.number().nullish(),
  lt: z.number().nullish(),
});

export const NetworksHardwareModelFilterSchema: z.ZodObject<
  Properties<NetworksHardwareModelFilter>
> = z.object({
  in: z.array(DeviceModelSchema),
  not: z.boolean().default(false),
});

export const NetworksCompanySupportTierFilterSchema: z.ZodObject<
  Properties<NetworksCompanySupportTierFilter>
> = z.object({
  in: z.array(CompanySupportTierSchema),
  not: z.boolean().default(false),
});

export const NetworksBooleanFilterSchema: z.ZodObject<Properties<NetworksBooleanFilter>> = z.object(
  {
    eq: z.boolean(),
  },
);

export const NetworkThreatsFilterInputSchema: z.ZodObject<Properties<NetworkThreatsFilterInput>> =
  z.object({
    timeFilter: z.lazy(() => MetricsFilterInputSchema),
  });

export const MetricsSerialNumberDirectionInputSchema: z.ZodObject<
  Properties<MetricsSerialNumberDirectionInput>
> = z.object({
  directions: z.array(TrafficDirectionSchema),
  serialNumber: z.string(),
});

export const UpdateMaintenanceWindowInputSchema: z.ZodObject<
  Properties<UpdateMaintenanceWindowInput>
> = z.object({
  endDayOfWeekLocal: z.number().nullish(),
  endHourOfDayLocal: z.number().nullish(),
  excludedDaysOfWeek: z.array(z.number()).nullish(),
  isOptedOut: z.boolean().nullish(),
  recurrence: MaintenanceWindowRecurrenceSchema.nullish(),
  startDayOfWeekLocal: z.number().nullish(),
  startHourOfDayLocal: z.number().nullish(),
});

export const InternetServicePlanConnectionInputSchema: z.ZodObject<
  Properties<InternetServicePlanConnectionInput>
> = z.object({
  product: IspProductSchema,
  status: IspStatusSchema,
});

export const UpdateIdsipsConfigInputSchema: z.ZodObject<Properties<UpdateIdsipsConfigInput>> =
  z.object({
    enabledVLANUUIDs: z.array(z.string()).nullish(),
    isEastWestProtectionEnabled: z.boolean().nullish(),
    minSeverity: z.number().nullish(),
    mode: IdsipsModeSchema.nullish(),
  });

export const GetNotificationPreferencesInputSchema: z.ZodObject<
  Properties<GetNotificationPreferencesInput>
> = z.object({
  companySlug: z.string(),
  controllerName: z.string(),
});

export const GetCompanyUserInputSchema: z.ZodObject<Properties<GetCompanyUserInput>> = z.object({
  includeDeleted: z.boolean().nullish(),
});

export const GetCaptivePortalInputSchema: z.ZodObject<Properties<GetCaptivePortalInput>> = z.object(
  {
    TODO: z.boolean().nullish(),
  },
);

export const DownloadUrlInputSchema: z.ZodObject<Properties<DownloadUrlInput>> = z.object({
  s3Key: z.string(),
});

export const DiscoveredServicesInputSchema: z.ZodObject<Properties<DiscoveredServicesInput>> =
  z.object({
    numMinutesLookback: z.number(),
    serviceTypes: z.array(ServiceTypeSchema),
  });

export const DevicesForNetworkFilterSchema: z.ZodObject<Properties<DevicesForNetworkFilter>> =
  z.object({
    deviceType: VirtualDeviceTypeSchema.nullish(),
  });

export const HardwareDevicesFilterSchema: z.ZodObject<Properties<HardwareDevicesFilter>> = z.object(
  {
    deviceModel: DeviceModelSchema.nullish(),
    deviceType: DeviceTypeSchema.nullish(),
    limit: z.number().default(200).nullish(),
    offset: z.number().nullish(),
  },
);

export const DeletePermittedEmailDomainInputSchema: z.ZodObject<
  Properties<DeletePermittedEmailDomainInput>
> = z.object({
  companySlug: z.string(),
  permittedEmailDomainSID: z.string(),
});

export const CreateVirtualDeviceTagInputSchema: z.ZodObject<
  Properties<CreateVirtualDeviceTagInput>
> = z.object({
  name: z.string(),
  networkUUID: z.string(),
});

export const CreateSsoVerifyInputSchema: z.ZodObject<Properties<CreateSsoVerifyInput>> = z.object({
  code: z.string(),
  source: SsoVerificationSourceSchema.nullish(),
  state: z.string().nullish(),
});

export const CreateRadioSettingsInputSchema: z.ZodObject<Properties<CreateRadioSettingsInput>> =
  z.object({
    band2_4GPrimaryChannel: z.number(),
    band2_4GSecondaryChannel: z.number().nullish(),
    band2_4GTransmitPowerdBm: z.number(),
    band5GPrimaryChannel: z.number(),
    band5GSecondaryChannel: z.number().nullish(),
    band5GTransmitPowerdBm: z.number(),
  });

export const CreateRadioProfileInputSchema: z.ZodObject<Properties<CreateRadioProfileInput>> =
  z.object({
    band2_4GAllowedChannels: z.array(z.number()).nullish(),
    band2_4GAutoChannelIsEnabled: z.boolean(),
    band2_4GAutoChannelWidthIsEnabled: z.boolean(),
    band2_4GAutoTxPowerIsEnabled: z.boolean(),
    band2_4GAutoTxPowerMaxdBm: z.number().nullish(),
    band2_4GAutoTxPowerMindBm: z.number().nullish(),
    band2_4GChannelWidthMHz: z.number().nullish(),
    band2_4GIsEnabled: z.boolean(),
    band5GAllowedChannels: z.array(z.number()).nullish(),
    band5GAutoChannelIsEnabled: z.boolean(),
    band5GAutoChannelWidthIsEnabled: z.boolean(),
    band5GAutoTxPowerIsEnabled: z.boolean(),
    band5GAutoTxPowerMaxdBm: z.number().nullish(),
    band5GAutoTxPowerMindBm: z.number().nullish(),
    band5GChannelWidthMHz: z.number().nullish(),
    band5GIsEnabled: z.boolean(),
    isDefault: z.boolean(),
    name: z.string(),
  });

export const CreateRackElevationInputSchema: z.ZodObject<Properties<CreateRackElevationInput>> =
  z.object({
    horizontalIndex: z.number().nullish(),
    label: z.string(),
    notes: z.array(z.lazy(() => RackElevationNoteInputSchema)).nullish(),
    rackMountUnitCount: z.number(),
  });

export const CreatePreSignedUploadUrlInputSchema: z.ZodObject<
  Properties<CreatePreSignedUploadUrlInput>
> = z.object({
  fileExtension: z.string(),
  folder: AllowedFolderSchema,
});

export const CreatePermittedEmailDomainInputSchema: z.ZodObject<
  Properties<CreatePermittedEmailDomainInput>
> = z.object({
  companySlug: z.string(),
  domain: z.string(),
});

export const CreateOneToOneNatRuleInputSchema: z.ZodObject<Properties<CreateOneToOneNatRuleInput>> =
  z.object({
    description: z.string().nullish(),
    externalIPAddr: z.string(),
    externalPhyInterfaceUUID: z.string(),
    internalIPAddr: z.string(),
    isEnabled: z.boolean(),
    name: z.string(),
  });

export const CreateNotificationSettingInputSchema: z.ZodObject<
  Properties<CreateNotificationSettingInput>
> = z.object({
  alertReceiverUUID: z.string(),
  arguments: z.array(z.lazy(() => NotificationSettingArgumentInputSchema)),
  createdBy: z.number().nullish(),
  isEnabled: z.boolean().nullish(),
  notificationDefinitionName: z.string(),
});

export const CreateNosFeatureInputSchema: z.ZodObject<Properties<CreateNosFeatureInput>> = z.object(
  {
    description: z.string(),
    featureFlag: z.string().nullish(),
    key: z.string(),
    maxMajorVersion: z.number().nullish(),
    maxMinorVersion: z.number().nullish(),
    maxPatchVersion: z.number().nullish(),
    minMajorVersion: z.number(),
    minMinorVersion: z.number(),
    minPatchVersion: z.number(),
    name: z.string(),
  },
);

export const UpdateSecurityApplianceProfileInputSchema: z.ZodObject<
  Properties<UpdateSecurityApplianceProfileInput>
> = z.object({
  hostMonitoringIntervalSec: z.number().nullish(),
  hostMonitoringTimeoutSec: z.number().nullish(),
  multiWANAlgorithm: MultiWanAlgorithmSchema.nullish(),
  multiWANUpdateCooldownSec: z.number().nullish(),
  multiWANUpdateLookback: z.number().nullish(),
  multiWANUpdateThreshold: z.number().nullish(),
});

export const CreateNetworkClientCpeBindingInputSchema: z.ZodObject<
  Properties<CreateNetworkClientCpeBindingInput>
> = z.object({
  cpeUUID: z.string(),
  macAddress: z.string(),
});

export const CreateNosUpgradeGroupInputSchema: z.ZodObject<Properties<CreateNosUpgradeGroupInput>> =
  z.object({
    description: z.string().nullish(),
    name: z.string(),
    priority: z.number().nullish(),
    timeoutDurationSeconds: z.number().default(600),
    virtualDeviceUUIDs: z.array(z.string()).nullish(),
  });

export const CreateMarketingChangelogEntryInputSchema: z.ZodObject<
  Properties<CreateMarketingChangelogEntryInput>
> = z.object({
  copy: z.string().nullish(),
  heading: z.string(),
  imageURL: z.string().nullish(),
  linkURL: z.string().nullish(),
  visibleEndAt: z.string(),
  visibleStartAt: z.string(),
});

export const CreateMailingAddressInputSchema: z.ZodObject<Properties<CreateMailingAddressInput>> =
  z.object({
    city: z.string(),
    countryISO2: z.string(),
    line1: z.string(),
    line2: z.string().nullish(),
    postalCode: z.string().nullish(),
    subdivisionCode: z.string().nullish(),
  });

export const CreateInternalNoteInputSchema: z.ZodObject<Properties<CreateInternalNoteInput>> =
  z.object({
    isImportant: z.boolean().default(false),
    note: z.string(),
    photoS3Keys: z.array(z.string()).nullish(),
  });

export const CreateIpSecTunnelInputSchema: z.ZodObject<Properties<CreateIpSecTunnelInput>> =
  z.object({
    authenticationAlgorithm: IpSecAuthenticationAlgorithmSchema.nullish(),
    encryptionAlgorithm: IpSecEncryptionAlgorithmSchema.nullish(),
    isClientVPNBound: z.boolean().nullish(),
    isEnabled: z.boolean(),
    isInitiator: z.boolean().default(false),
    keyExchangeDHGroup: IpSecDhGroupSchema.nullish(),
    leftID: z.string(),
    name: z.string(),
    peerType: IpSecPeerTypeSchema.nullish(),
    phyInterfaceUUID: z.string(),
    presharedKey: z.string(),
    right: z.string().nullish(),
    rightID: z.string(),
    rightPrefixes: z.array(z.string()),
    vlanUUIDs: z.array(z.string()),
  });

export const CreateIdsipsConfigInputSchema: z.ZodObject<Properties<CreateIdsipsConfigInput>> =
  z.object({
    enabledVLANUUIDs: z.array(z.string()),
    isEastWestProtectionEnabled: z.boolean().default(false),
    minSeverity: z.number().default(2),
    mode: IdsipsModeSchema,
  });

export const CreateHostMonitoringTargetInputSchema: z.ZodObject<
  Properties<CreateHostMonitoringTargetInput>
> = z.object({
  description: z.string().nullish(),
  isEnabled: z.boolean().default(true),
  isUsedForWANFailover: z.boolean().default(false),
  targetHost: z.string(),
  type: HostMonitoringTypeSchema,
});

export const CreateFloorPlanInputSchema: z.ZodObject<Properties<CreateFloorPlanInput>> = z.object({
  description: z.string().nullish(),
  imageS3Key: z.string(),
  label: z.string(),
});

export const PortRangeInputSchema: z.ZodObject<Properties<PortRangeInput>> = z.object({
  lower: z.number(),
  upper: z.number(),
});

export const CreateDeviceHeartbeatInputSchema: z.ZodObject<Properties<CreateDeviceHeartbeatInput>> =
  z.object({
    serialNumber: z.string(),
  });

export const CreateDhcpStaticMappingInputSchema: z.ZodObject<
  Properties<CreateDhcpStaticMappingInput>
> = z.object({
  hostname: z.string().nullish(),
  ipAddress: z.string(),
  macAddress: z.string(),
  name: z.string().nullish(),
});

export const CreateDhcpRuleInputSchema: z.ZodObject<Properties<CreateDhcpRuleInput>> = z.object({
  dnsCacheIsEnabled: z.boolean().default(true),
  dnsCacheMaxTTL: z.number().default(60),
  dnsCacheSize: z.number().default(100000),
  dnsSearchDomains: z.array(z.string()),
  dnsUpstreamServers: z.array(z.string()),
  dnsUseGatewayProxy: z.boolean().default(true),
  endIPAddress: z.string(),
  gatewayIPAddress: z.string(),
  gatewayPrefixLength: z.number(),
  hasGatewayOverride: z.boolean().default(false),
  isIPv6: z.boolean(),
  leaseDurationSeconds: z.number(),
  startIPAddress: z.string(),
});

export const CreateDhcpReservedRangeInputSchema: z.ZodObject<
  Properties<CreateDhcpReservedRangeInput>
> = z.object({
  endIPAddress: z.string(),
  startIPAddress: z.string(),
});

export const UpdateDhcpOptionInputSchema: z.ZodObject<Properties<UpdateDhcpOptionInput>> = z.object(
  {
    data: z.string().nullish(),
    dataType: DhcpOptionDataTypeSchema.nullish(),
    description: z.string().nullish(),
  },
);

export const CreateDhcpOptionInputSchema: z.ZodObject<Properties<CreateDhcpOptionInput>> = z.object(
  {
    code: z.number(),
    data: z.string(),
    dataType: DhcpOptionDataTypeSchema.nullish(),
    description: z.string().nullish(),
  },
);

export const CreateCompanyUserInputSchema: z.ZodObject<Properties<CreateCompanyUserInput>> =
  z.object({
    companyMembershipRole: CompanyMembershipRoleSchema,
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    roleAssignments: z.array(z.lazy(() => UserRoleInputSchema)).nullish(),
  });

export const CreateCompanyInputSchema: z.ZodObject<Properties<CreateCompanyInput>> = z.object({
  isProspective: z.boolean().nullish(),
  name: z.string(),
  slug: z.string(),
});

export const CreateClientVpnClientInputSchema: z.ZodObject<Properties<CreateClientVpnClientInput>> =
  z.object({
    email: z.string(),
    isEnabled: z.boolean().default(true),
    name: z.string(),
    publicKey: z.string(),
  });

export const CreateBulkNosUpgradeInputSchema: z.ZodObject<Properties<CreateBulkNosUpgradeInput>> =
  z.object({
    networkUUIDs: z.array(z.string()),
    nosVersionID: z.number(),
  });

export const CreateAutoWifiObserverSettingInputSchema: z.ZodObject<
  Properties<CreateAutoWifiObserverSettingInput>
> = z.object({
  commands: z.array(z.lazy(() => WosObserverCommandInputSchema)).nullish(),
  commit: z.boolean(),
  durationMsec: z.number(),
  filters: z.lazy(() => AutoWifiObserverFiltersInputSchema),
  networkUUID: z.string(),
  numSpatialStreams: z.number().nullish(),
  snrThreshold: z.number(),
});

export const CreateAutoVpnRouteInputSchema: z.ZodObject<Properties<CreateAutoVpnRouteInput>> =
  z.object({
    dstGateway: z.string(),
    dstPrefixLength: z.number(),
    isEnabled: z.boolean().default(true),
    name: z.string(),
  });

export const CreateAutoVpnGroupInputSchema: z.ZodObject<Properties<CreateAutoVpnGroupInput>> =
  z.object({
    hubNetworkUUID: z.string(),
    isHubFailoverEnabled: z.boolean().default(true),
    members: z.array(z.lazy(() => AddNetworkToAutoVpnGroupInputSchema)).nullish(),
    name: z.string(),
    uplink: z.lazy(() => CreateAutoVpnGroupHubUplinkInputSchema),
  });

export const CreateAutoVpnGroupHubUplinkInputSchema: z.ZodObject<
  Properties<CreateAutoVpnGroupHubUplinkInput>
> = z.object({
  address: z.string(),
  addressPrefixLength: z.number(),
  isFailoverEnabled: z.boolean().default(true),
  phyInterfaceUUID: z.string(),
});

export const UpdateAutoVpnGroupInputSchema: z.ZodObject<Properties<UpdateAutoVpnGroupInput>> =
  z.object({
    isHubFailoverEnabled: z.boolean().nullish(),
    name: z.string().nullish(),
    uplink: z.lazy(() => CreateAutoVpnGroupHubUplinkInputSchema.nullish()),
  });

export const CreateApplicationDnsFirewallRuleInputSchema: z.ZodObject<
  Properties<CreateApplicationDnsFirewallRuleInput>
> = z.object({
  action: FirewallRuleActionSchema,
  applicationID: z.number().nullish(),
  categoryID: z.number().nullish(),
  domain: z.string().nullish(),
  groupID: z.number().nullish(),
  isEnabled: z.boolean().default(true),
  name: z.string(),
  priority: z.number().nullish(),
});

export const CreateAlertReceiverWithTargetsInputSchema: z.ZodObject<
  Properties<CreateAlertReceiverWithTargetsInput>
> = z.object({
  label: z.string(),
  targets: z.array(z.lazy(() => AlertTargetInputSchema)),
});

export const CreateAddressInputSchema: z.ZodObject<Properties<CreateAddressInput>> = z.object({
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  country: z.string(),
  postalCode: z.string(),
  state: z.string(),
});

export const CreateInternetServicePlanInputSchema: z.ZodObject<
  Properties<CreateInternetServicePlanInput>
> = z.object({
  address: z.lazy(() => CreateAddressInputSchema.nullish()),
  connection: z.lazy(() => InternetServicePlanConnectionInputSchema),
  ipConfiguration: z.lazy(() => InternetServicePlanIpInputSchema),
  providerUUID: z.string(),
});

export const ConfigureSsoInputSchema: z.ZodObject<Properties<ConfigureSsoInput>> = z.object({
  companySlug: z.string(),
  type: SsoAdminPortalTypeSchema,
});

export const UpdateCompanyUserInputSchema: z.ZodObject<Properties<UpdateCompanyUserInput>> =
  z.object({
    companyMembershipRole: CompanyMembershipRoleSchema.nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
  });

export const UpdateAutoVpnRouteInputSchema: z.ZodObject<Properties<UpdateAutoVpnRouteInput>> =
  z.object({
    dstGateway: z.string().nullish(),
    dstPrefixLength: z.number().nullish(),
    isEnabled: z.boolean().nullish(),
    name: z.string().nullish(),
  });

export const InternetServicePlanIpInputSchema: z.ZodObject<Properties<InternetServicePlanIpInput>> =
  z.object({
    controllerIP: z.string().nullish(),
    controllerName: z.string().nullish(),
    firstUsableIP: z.string().nullish(),
    gatewayAddr: z.string().nullish(),
    lastUsableIP: z.string().nullish(),
    staticIPRange: z.string().nullish(),
  });

export const UpdateEncryption8021XInputSchema: z.ZodObject<Properties<UpdateEncryption8021XInput>> =
  z.object({
    accountingServerIPAddress: z.string().nullish(),
    accountingServerPort: z.number().nullish(),
    accountingServerSecret: z.string().nullish(),
    authServerIPAddress: z.string().nullish(),
    authServerPort: z.number().nullish(),
    authServerSecret: z.string().nullish(),
    label: z.string().nullish(),
  });

export const UpdateDnsHostMappingInputSchema: z.ZodObject<Properties<UpdateDnsHostMappingInput>> =
  z.object({
    destinationDomain: z.string().nullish(),
    destinationIPAddress: z.string().nullish(),
    isEnabled: z.boolean().nullish(),
    overrideDomain: z.string().nullish(),
  });

export const UpdateDhcpRuleInputSchema: z.ZodObject<Properties<UpdateDhcpRuleInput>> = z.object({
  dnsCacheIsEnabled: z.boolean().nullish(),
  dnsCacheMaxTTL: z.number().nullish(),
  dnsCacheSize: z.number().nullish(),
  dnsSearchDomains: z.array(z.string()).nullish(),
  dnsUpstreamServers: z.array(z.string()).nullish(),
  dnsUseGatewayProxy: z.boolean().nullish(),
  endIPAddress: z.string().nullish(),
  gatewayIPAddress: z.string().nullish(),
  gatewayPrefixLength: z.number().nullish(),
  hasGatewayOverride: z.boolean().nullish(),
  isIPv6: z.boolean().nullish(),
  leaseDurationSeconds: z.number().nullish(),
  reservedRanges: z.array(z.lazy(() => UpdateDhcpReservedRangeUuidInputSchema)).nullish(),
  startIPAddress: z.string().nullish(),
  staticMappings: z.array(z.lazy(() => UpdateDhcpStaticMappingUuidInputSchema)).nullish(),
});

export const UpdateDhcpReservedRangeInputSchema: z.ZodObject<
  Properties<UpdateDhcpReservedRangeInput>
> = z.object({
  endIPAddress: z.string().nullish(),
  startIPAddress: z.string().nullish(),
});

export const CreateEncryption8021XInputSchema: z.ZodObject<Properties<CreateEncryption8021XInput>> =
  z.object({
    accountingServerIPAddress: z.string().nullish(),
    accountingServerPort: z.number().nullish(),
    accountingServerSecret: z.string().nullish(),
    authServerIPAddress: z.string(),
    authServerPort: z.number(),
    authServerSecret: z.string(),
    label: z.string(),
    networkUUID: z.string(),
  });

export const CreateDnsHostMappingInputSchema: z.ZodObject<Properties<CreateDnsHostMappingInput>> =
  z.object({
    destinationDomain: z.string().nullish(),
    destinationIPAddress: z.string().nullish(),
    isEnabled: z.boolean().default(true),
    overrideDomain: z.string(),
  });

export const ClientMetricsTimeseriesFilterInputSchema: z.ZodObject<
  Properties<ClientMetricsTimeseriesFilterInput>
> = z.object({
  bands: z.array(RadioBandSchema).nullish(),
  channels: z.array(z.number()).nullish(),
  excludeObservers: z.boolean().default(false).nullish(),
  ssidUUIDs: z.array(z.string()).nullish(),
  timeFilter: z.lazy(() => MetricsFilterInputSchema),
});

export const UpdateVlanInputSchema: z.ZodObject<Properties<UpdateVlanInput>> = z.object({
  createDHCPRules: z.array(z.lazy(() => CreateDhcpRuleInputSchema)).nullish(),
  deleteDHCPRules: z.array(z.string()).nullish(),
  description: z.string().nullish(),
  igmpQueryCount: z.number().nullish(),
  igmpQueryIntervalSec: z.number().nullish(),
  igmpResponseIntervalSec: z.number().nullish(),
  igmpStartupQueryIntervalSec: z.number().nullish(),
  igmpVersion: z.number().nullish(),
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocolSchema.nullish(),
  ipV4ClientGateway: z.string().nullish(),
  ipV4ClientPrefixLength: z.number().nullish(),
  ipV6ClientAssignmentProtocol: ClientAssignmentProtocolSchema.nullish(),
  ipV6ClientGateway: z.string().nullish(),
  ipV6ClientPrefixLength: z.number().nullish(),
  isEnabled: z.boolean().nullish(),
  isIGMPSnoopingEnabled: z.boolean().nullish(),
  isInternal: z.boolean().nullish(),
  isMLDSnoopingEnabled: z.boolean().nullish(),
  isMulticastReflectionEnabled: z.boolean().nullish(),
  isWANDenied: z.boolean().nullish(),
  mldQueryIntervalSec: z.number().nullish(),
  mldVersion: z.number().nullish(),
  name: z.string().nullish(),
  participatesInVPNs: z.boolean().nullish(),
  permittedInterVLANCommunicationVLANUUIDs: z.array(z.string()).nullish(),
  updateDHCPRules: z.array(z.lazy(() => UpdateVlandhcpRuleInputSchema)).nullish(),
  vlanID: z.number().nullish(),
});

export const CreateVlanInputSchema: z.ZodObject<Properties<CreateVlanInput>> = z.object({
  createDHCPRules: z.array(z.lazy(() => CreateDhcpRuleInputSchema)).nullish(),
  description: z.string().nullish(),
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocolSchema.nullish(),
  ipV4ClientGateway: z.string().nullish(),
  ipV4ClientPrefixLength: z.number().nullish(),
  ipV6ClientAssignmentProtocol: ClientAssignmentProtocolSchema.nullish(),
  ipV6ClientGateway: z.string().nullish(),
  ipV6ClientPrefixLength: z.number().nullish(),
  isEnabled: z.boolean().default(true),
  isInternal: z.boolean().default(false),
  isMulticastReflectionEnabled: z.boolean().default(false),
  isWANDenied: z.boolean().default(false),
  name: z.string(),
  participatesInVPNs: z.boolean().default(false),
  permittedInterVLANCommunicationVLANUUIDs: z.array(z.string()).nullish(),
  vlanID: z.number(),
});

export const ChannelUtilizationTimeseriesFilterInputSchema: z.ZodObject<
  Properties<ChannelUtilizationTimeseriesFilterInput>
> = z.object({
  timeFilter: z.lazy(() => MetricsFilterInputSchema),
});

export const CaptivePortalDisplaySettingsInputSchema: z.ZodObject<
  Properties<CaptivePortalDisplaySettingsInput>
> = z.object({
  backgroundColor: z.string().nullish(),
  callToActionColor: z.string().nullish(),
  fontColor: z.string().nullish(),
  hidePoweredByMeter: z.boolean().nullish(),
});

export const CaptivePortalSettingsInputSchema: z.ZodObject<Properties<CaptivePortalSettingsInput>> =
  z.object({
    allowedHosts: z.array(z.string()).nullish(),
    authLifetimeSec: z.number().nullish(),
    callToAction: z.string().nullish(),
    displaySettings: z.lazy(() => CaptivePortalDisplaySettingsInputSchema.nullish()),
    externalPortalURL: z.string().nullish(),
    isEnabled: z.boolean().nullish(),
    logoImageS3Key: z.string().nullish(),
    name: z.string().nullish(),
    redirectURL: z.string().nullish(),
    termsAndConditions: z.string().nullish(),
    vlanUUIDs: z.array(z.string()).nullish(),
  });

export const CvEsByNetworkMacAddressesInputSchema: z.ZodObject<
  Properties<CvEsByNetworkMacAddressesInput>
> = z.object({
  macAddresses: z.array(z.string()),
  networkUUID: z.string(),
});

export const CpEsFilterInputSchema: z.ZodObject<Properties<CpEsFilterInput>> = z.object({
  limit: z.number().default(100),
  offset: z.number().default(0),
  part: z.string().nullish(),
  product: z.string().nullish(),
  vendor: z.string().nullish(),
  version: z.string().nullish(),
});

export const BindVlanToPhyInterfaceInputSchema: z.ZodObject<
  Properties<BindVlanToPhyInterfaceInput>
> = z.object({
  isTagged: z.boolean(),
  phyInterfaceUUID: z.string(),
  vlanUUID: z.string(),
});

export const BindPolicyRoutingRulesInputSchema: z.ZodObject<
  Properties<BindPolicyRoutingRulesInput>
> = z.object({
  binding: z.lazy(() => PolicyRoutingRuleBindingInputSchema),
});

export const BindPolicyRoutingRuleInputSchema: z.ZodObject<Properties<BindPolicyRoutingRuleInput>> =
  z.object({
    binding: z.lazy(() => PolicyRoutingRuleBindingInputSchema),
    metric: z.number().nullish(),
  });

export const BindFirewallRuleToVlanSchema: z.ZodObject<Properties<BindFirewallRuleToVlan>> =
  z.object({
    firewallRuleUUID: z.string(),
    metric: z.number().nullish(),
    vlanUUID: z.string(),
  });

export const BindFirewallRuleToPhyInterfaceSchema: z.ZodObject<
  Properties<BindFirewallRuleToPhyInterface>
> = z.object({
  firewallRuleUUID: z.string(),
  metric: z.number().nullish(),
  phyInterfaceUUID: z.string(),
});

export const AutoWifiObserverSsidFilterSchema: z.ZodObject<Properties<AutoWifiObserverSsidFilter>> =
  z.object({
    SSIDUUID: z.string(),
    password8021X: z.string().nullish(),
    username8021X: z.string().nullish(),
  });

export const AutoWifiObserverFiltersInputSchema: z.ZodObject<
  Properties<AutoWifiObserverFiltersInput>
> = z.object({
  SSIDs: z.array(AutoWifiObserverSsidFilterSchema),
  bands: z.array(RadioBandSchema),
  virtualDeviceUUIDs: z.array(z.string()).nullish(),
});

export const AutoChannelSelectionJobInputSchema: z.ZodObject<
  Properties<AutoChannelSelectionJobInput>
> = z.object({
  bands: z.array(RadioBandSchema),
  excludeLowInterferenceAPs: z.boolean().default(false),
  scheduledAtNextMaintenanceWindow: z.boolean().default(false),
});

export const AssignHardwareDeviceToVirtualDeviceInputSchema: z.ZodObject<
  Properties<AssignHardwareDeviceToVirtualDeviceInput>
> = z.object({
  serialNumber: z.string(),
  virtualDeviceUUID: z.string(),
});

export const NotificationSettingArgumentInputSchema: z.ZodObject<
  Properties<NotificationSettingArgumentInput>
> = z.object({
  parameterName: z.string(),
  value: definedNonNullAnySchema.nullish(),
});

export const AllClientMetricsTimeseriesFilterInputSchema: z.ZodObject<
  Properties<AllClientMetricsTimeseriesFilterInput>
> = z.object({
  timeFilter: z.lazy(() => MetricsFilterInputSchema),
});

export const UpdateAlertTargetWebhookInputSchema: z.ZodObject<
  Properties<UpdateAlertTargetWebhookInput>
> = z.object({
  authType: WebhookAuthTypeSchema.nullish(),
  authValue: z.string().nullish(),
  label: z.string().nullish(),
  signingSecret: z.string().nullish(),
  url: z.string().nullish(),
});

export const CreateAlertTargetWebhookInputSchema: z.ZodObject<
  Properties<CreateAlertTargetWebhookInput>
> = z.object({
  authType: WebhookAuthTypeSchema.nullish(),
  authValue: z.string().nullish(),
  label: z.string().nullish(),
  signingSecret: z.string().nullish(),
  url: z.string(),
});

export const AlertTargetInputSchema: z.ZodObject<Properties<AlertTargetInput>> = z.object({
  alertTargetType: AlertTargetTypeSchema,
  alertTargetUUID: z.string().nullish(),
  emailAddress: z.string().nullish(),
});

export const AddNetworkToAutoVpnGroupInputSchema: z.ZodObject<
  Properties<AddNetworkToAutoVpnGroupInput>
> = z.object({
  isFailoverEnabled: z.boolean().default(true),
  networkUUID: z.string(),
  permittedVLANUUIDs: z.array(z.string()).nullish(),
  phyInterfaceUUID: z.string(),
});

export const ActiveClientsInputSchema: z.ZodObject<Properties<ActiveClientsInput>> = z.object({
  includeMeterHardware: z.boolean().default(true).nullish(),
});

export const MintTokenInputSchema: z.ZodObject<Properties<MintTokenInput>> = z.object({
  permissions: z.array(PermissionSchema),
  principalName: z.string(),
  principalRole: RoleNameSchema.nullish(),
  principalType: PrincipalTypeSchema,
  validityDuration: z.string().nullish(),
});

export const UserRoleInputSchema: z.ZodObject<Properties<UserRoleInput>> = z.object({
  networkUUID: z.string().nullish(),
  roleName: RoleNameSchema,
});

export const UpdateWifiIpskInputSchema: z.ZodObject<Properties<UpdateWifiIpskInput>> = z.object({
  clientMACs: z.array(z.string()).nullish(),
  label: z.string().nullish(),
  password: z.string().nullish(),
  vlanUUID: z.string().nullish(),
});

export const UpdateVirtualDeviceInputSchema: z.ZodObject<Properties<UpdateVirtualDeviceInput>> =
  z.object({
    description: z.string().nullish(),
    deviceModel: DeviceModelSchema.nullish(),
    enableConsolePort: z.boolean().nullish(),
    enableLEDDarkMode: z.boolean().nullish(),
    isDebugLogEnabled: z.boolean().nullish(),
    label: z.string().nullish(),
    nosUpgradeGroupUUID: z.string().nullish(),
    radioProfileUUID: z.string().nullish(),
    radioSettings: z.lazy(() => UpdateRadioSettingsInputSchema.nullish()),
    switchProfileUUID: z.string().nullish(),
    tagUUIDs: z.array(z.string()).nullish(),
  });

export const UpdateSwitchProfileInputSchema: z.ZodObject<Properties<UpdateSwitchProfileInput>> =
  z.object({
    encryption8021XGuestVLANUUID: z.string().nullish(),
    encryption8021XUUIDs: z.array(z.string()).nullish(),
    flipClearFlapStateAfterSec: z.number().nullish(),
    flipMaxRetryCount: z.number().nullish(),
    flipRetryPeriodSec: z.number().nullish(),
    flipWindowSec: z.number().nullish(),
    flipWindowThreshold: z.number().nullish(),
    isFLIPEnabled: z.boolean().nullish(),
    isIGMPSnoopingEnabled: z.boolean().nullish(),
    isMLDSnoopingEnabled: z.boolean().nullish(),
    isMulticastFilteringEnabled: z.boolean().nullish(),
    isVoiceVLANEnabled: z.boolean().nullish(),
    mtu: z.number().nullish(),
    name: z.string().nullish(),
    stpBridgePriority: z.number().nullish(),
    stpForwardingDelaySeconds: z.number().nullish(),
    stpHelloIntervalSeconds: z.number().nullish(),
    stpMaxAgeSeconds: z.number().nullish(),
    stpTransmitHoldCount: z.number().nullish(),
    voiceCosValue: z.number().nullish(),
    voiceDscpValue: z.number().nullish(),
    voiceVLANUUID: z.string().nullish(),
  });

export const UpdateSsidInputSchema: z.ZodObject<Properties<UpdateSsidInput>> = z.object({
  band2GMinimumBasicRateKbps: z.number().nullish(),
  band5GMinimumBasicRateKbps: z.number().nullish(),
  broadcastSchedule: z.array(z.lazy(() => SsidBroadcastScheduleIntervalInputSchema)).nullish(),
  clientSteeringAssociationSteeringIsEnabled: z.boolean().nullish(),
  clientSteeringLoadBalancingClientMinThreshold: z.number().nullish(),
  clientSteeringPostassociationSteeringIsEnabled: z.boolean().nullish(),
  clientSteeringPreassociationRSSIMinThreshold: z.number().nullish(),
  description: z.string().nullish(),
  disabledAccessPointVirtualDeviceUUIDs: z.array(z.string()).nullish(),
  dtimPeriod: z.number().nullish(),
  dynamicVLANMode: SsidDynamicVlanModeSchema.nullish(),
  enabledAccessPointVirtualDeviceUUIDs: z.array(z.string()).nullish(),
  encryption80211wProtectedManagementFramesMode:
    SsidEncryption80211wProtectedManagementFramesModeSchema.nullish(),
  encryptionProtocol: SsidEncryptionProtocolSchema.nullish(),
  hs20AccessNetworkType: z.number().nullish(),
  hs20ClearAll: z.boolean().nullish(),
  hs20DomainNames: z.array(z.string()).nullish(),
  hs20Enabled: z.boolean().nullish(),
  hs20MccMncPairs: z.array(z.string()).nullish(),
  hs20NaiRealms: z.array(z.string()).nullish(),
  hs20OperatorNames: z.array(z.string()).nullish(),
  hs20RoamingConsortiumOIs: z.array(z.string()).nullish(),
  hs20VenueGroup: z.number().nullish(),
  hs20VenueNames: z.array(z.string()).nullish(),
  hs20VenueType: z.number().nullish(),
  is80211axEnabled: z.boolean().nullish(),
  isAPDebugLogEnabled: z.boolean().nullish(),
  isBand2GAllowed: z.boolean().nullish(),
  isBand5GAllowed: z.boolean().nullish(),
  isEnabled: z.boolean().nullish(),
  isEnabledForAllAccessPoints: z.boolean().nullish(),
  isGuest: z.boolean().nullish(),
  isHidden: z.boolean().nullish(),
  isIsolateClientsEnabled: z.boolean().nullish(),
  isMulticastToUnicastEnabled: z.boolean().nullish(),
  isProxyARPEnabled: z.boolean().nullish(),
  isRoaming80211kEnabled: z.boolean().nullish(),
  isRoaming80211rEnabled: z.boolean().nullish(),
  isRoaming80211vEnabled: z.boolean().nullish(),
  multicastToUnicastClientThreshold: z.number().nullish(),
  nasIdentifier: z.string().nullish(),
  password: z.string().nullish(),
  passwordRotationCadence: SsidPasswordRotationCadenceSchema.nullish(),
  passwordRotationDayOfMonthLocal: z.number().nullish(),
  passwordRotationDayOfWeekLocal: z.number().nullish(),
  passwordRotationHourOfDayLocal: z.number().nullish(),
  primaryEncryption8021XUUID: z.string().nullish(),
  radiusCoaEnabled: z.boolean().nullish(),
  radiusCoaPort: z.number().nullish(),
  rtsCtsEnabledCode: z.number().nullish(),
  rtsThreshold: z.number().nullish(),
  secondaryEncryption8021XUUID: z.string().nullish(),
  ssid: z.string().nullish(),
  vlanUUID: z.string().nullish(),
});

export const UpdateRackElevationDeviceInputSchema: z.ZodObject<
  Properties<UpdateRackElevationDeviceInput>
> = z.object({
  isFrontFacing: z.boolean().nullish(),
  label: z.string().nullish(),
  notes: z.string().nullish(),
  portCount: z.number().nullish(),
  rackMountUnitIndexes: z.array(z.number()).nullish(),
  type: RackElevationDeviceTypeSchema.nullish(),
  virtualDeviceUUID: z.string().nullish(),
});

export const UpdatePortForwardingRuleInputSchema: z.ZodObject<
  Properties<UpdatePortForwardingRuleInput>
> = z.object({
  allowedRemoteIPAddresses: z.array(z.string()).nullish(),
  description: z.string().nullish(),
  externalIPAddress: z.string().nullish(),
  externalPhyInterfaceUUID: z.string().nullish(),
  externalPort: z.number().nullish(),
  isEnabled: z.boolean().nullish(),
  localIPAddress: z.string().nullish(),
  localPort: z.number().nullish(),
  name: z.string().nullish(),
  protocol: PortIpProtocolSchema.nullish(),
});

export const UpdatePolicyRoutingRuleInputSchema: z.ZodObject<
  Properties<UpdatePolicyRoutingRuleInput>
> = z.object({
  bindings: z.array(z.lazy(() => PolicyRoutingRuleBindingInputSchema)).nullish(),
  description: z.string().nullish(),
  dstPortRanges: z.array(z.lazy(() => PortRangeInputSchema)).nullish(),
  dstPrefix: z.string().nullish(),
  isEnabled: z.boolean().nullish(),
  name: z.string().nullish(),
  protocols: z.array(IpProtocolSchema).nullish(),
  srcPortRanges: z.array(z.lazy(() => PortRangeInputSchema)).nullish(),
  srcPrefix: z.string().nullish(),
  srcVLANUUID: z.string().nullish(),
});

export const UpdatePhyInterfaceInputSchema: z.ZodObject<Properties<UpdatePhyInterfaceInput>> =
  z.object({
    MTU: z.number().nullish(),
    allowedVLANUUIDs: z.array(z.string()).nullish(),
    description: z.string().nullish(),
    encryption8021XSettings: z.lazy(() => UpdatePhyInterface8021XSettingsInputSchema.nullish()),
    forcedPortSpeedMbps: z.number().nullish(),
    frameAcceptTypeFilter: PhyInterfaceFrameAcceptTypeFilterSchema.nullish(),
    ipv4ClientAddresses: z.array(z.string()).nullish(),
    ipv4ClientAssignmentProtocol: ClientAssignmentProtocolSchema.nullish(),
    ipv4ClientGateway: z.string().nullish(),
    ipv4ClientPrefixLength: z.number().nullish(),
    ipv6ClientAddresses: z.array(z.string()).nullish(),
    ipv6ClientAssignmentProtocol: ClientAssignmentProtocolSchema.nullish(),
    ipv6ClientGateway: z.string().nullish(),
    ipv6ClientPrefixLength: z.number().nullish(),
    isBoundToAllVLANs: z.boolean().nullish(),
    isEnabled: z.boolean().nullish(),
    isFLIPEnabled: z.boolean().nullish(),
    isFastLeaveEnabled: z.boolean().nullish(),
    isFlowControlEnabled: z.boolean().nullish(),
    isIngressFilteringEnabled: z.boolean().nullish(),
    isIsolationEnabled: z.boolean().nullish(),
    isPOEEnabled: z.boolean().nullish(),
    isSTPEdgePortEnabled: z.boolean().nullish(),
    isSTPEnabled: z.boolean().nullish(),
    isSTPRootGuardEnabled: z.boolean().nullish(),
    isStormControlEnabled: z.boolean().nullish(),
    isTrunkPort: z.boolean().nullish(),
    isUplink: z.boolean().nullish(),
    isVoiceVLANEnabled: z.boolean().nullish(),
    label: z.string().nullish(),
    maxMACAddressesAllowed: z.number().nullish(),
    nativeVLANUUID: z.string().nullish(),
    rxRateLimitKbps: z.number().nullish(),
    stormControlBroadcastTrafficPercent: z.number().nullish(),
    stormControlUnknownMulticastTrafficPercent: z.number().nullish(),
    stormControlUnknownUnicastTrafficPercent: z.number().nullish(),
    stpPathCost: z.number().nullish(),
    stpPriority: z.number().nullish(),
    txRateLimitKbps: z.number().nullish(),
    uplinkPriority: z.number().nullish(),
    uplinkVLANID: z.number().nullish(),
  });

export const UpdateOutletInterfaceInputSchema: z.ZodObject<Properties<UpdateOutletInterfaceInput>> =
  z.object({
    connectedVirtualDeviceUUID: z.string().nullish(),
    description: z.string().nullish(),
    isEnabled: z.boolean().nullish(),
    label: z.string().nullish(),
    powerCycleDelaySec: z.number().nullish(),
  });

export const UpdateOneToOneNatRuleInputSchema: z.ZodObject<Properties<UpdateOneToOneNatRuleInput>> =
  z.object({
    description: z.string().nullish(),
    externalIPAddr: z.string().nullish(),
    externalPhyInterfaceUUID: z.string().nullish(),
    internalIPAddr: z.string().nullish(),
    isEnabled: z.boolean().nullish(),
    name: z.string().nullish(),
  });

export const UpdateNotificationSettingInputSchema: z.ZodObject<
  Properties<UpdateNotificationSettingInput>
> = z.object({
  alertReceiverUUID: z.string().nullish(),
  arguments: z.array(z.lazy(() => NotificationSettingArgumentInputSchema)).nullish(),
  isEnabled: z.boolean().nullish(),
  updatedBy: z.number().nullish(),
});

export const UpdateNetworkThreatAllowlistEntryInputSchema: z.ZodObject<
  Properties<UpdateNetworkThreatAllowlistEntryInput>
> = z.object({
  reason: z.string().nullish(),
  ssidUUID: z.string().nullish(),
  wiredMAC: z.string().nullish(),
});

export const UpdateNetworkInputSchema: z.ZodObject<Properties<UpdateNetworkInput>> = z.object({
  IsWifiSteeringEnabled: z.boolean().nullish(),
  enabledServiceDiscoveryTypes: z.array(ServiceTypeSchema).nullish(),
  isActive: z.boolean().nullish(),
  isConfig1WosUpgradesEnabled: z.boolean().nullish(),
  isTemplate: z.boolean().nullish(),
  isTest: z.boolean().nullish(),
  isUpgradeSensitive: z.boolean().nullish(),
  label: z.string().nullish(),
  mailingAddressInput: z.lazy(() => CreateMailingAddressInputSchema.nullish()),
  mailingAddressUUID: z.string().nullish(),
  maintenanceWindow: z.lazy(() => UpdateMaintenanceWindowInputSchema.nullish()),
  multiWANAlgorithm: MultiWanAlgorithmSchema.nullish(),
  patchPanelDiagramURL: z.string().nullish(),
  slug: z.string().nullish(),
  squareFeet: z.number().nullish(),
  topologyImageS3Key: z.string().nullish(),
});

export const UpdateInternetServicePlanInputSchema: z.ZodObject<
  Properties<UpdateInternetServicePlanInput>
> = z.object({
  connection: z.lazy(() => InternetServicePlanConnectionInputSchema.nullish()),
  ipConfiguration: z.lazy(() => InternetServicePlanIpInputSchema.nullish()),
  providerUUID: z.string().nullish(),
  sublocationUUID: z.string().nullish(),
});

export const UpdateIpSecTunnelInputSchema: z.ZodObject<Properties<UpdateIpSecTunnelInput>> =
  z.object({
    authenticationAlgorithm: IpSecAuthenticationAlgorithmSchema.nullish(),
    encryptionAlgorithm: IpSecEncryptionAlgorithmSchema.nullish(),
    isClientVPNBound: z.boolean().nullish(),
    isEnabled: z.boolean().nullish(),
    isInitiator: z.boolean().nullish(),
    keyExchangeDHGroup: IpSecDhGroupSchema.nullish(),
    leftID: z.string().nullish(),
    name: z.string().nullish(),
    peerType: IpSecPeerTypeSchema.nullish(),
    phyInterfaceUUID: z.string().nullish(),
    presharedKey: z.string().nullish(),
    right: z.string().nullish(),
    rightID: z.string().nullish(),
    rightPrefixes: z.array(z.string()).nullish(),
    vlanUUIDs: z.array(z.string()).nullish(),
  });

export const UpdateHighAvailabilityPairInputSchema: z.ZodObject<
  Properties<UpdateHighAvailabilityPairInput>
> = z.object({
  advertisementIntervalMs: z.number().nullish(),
  advertisementVLANUUID: z.string().nullish(),
  backupPhyInterfaceUUID: z.string().nullish(),
  preempt: z.boolean().nullish(),
  primaryPhyInterfaceUUID: z.string().nullish(),
});

export const UpdateFirewallRuleInputSchema: z.ZodObject<Properties<UpdateFirewallRuleInput>> =
  z.object({
    action: FirewallRuleActionSchema.nullish(),
    boundPhyInterfaceUUIDs: z.array(z.string()).nullish(),
    boundVLANUUIDs: z.array(z.string()).nullish(),
    description: z.string().nullish(),
    dstClientVPNServerUUID: z.string().nullish(),
    dstIPSecTunnelUUID: z.string().nullish(),
    dstPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    dstPortRanges: z.array(z.lazy(() => PortRangeInputSchema)).nullish(),
    dstPrefix: z.string().nullish(),
    dstVLANUUID: z.string().nullish(),
    isBidirectional: z.boolean().nullish(),
    isEnabled: z.boolean().nullish(),
    isMeterInternal: z.boolean().nullish(),
    name: z.string().nullish(),
    priority: z.number().nullish(),
    protocol: IpProtocolSchema.nullish(),
    protocols: z.array(IpProtocolSchema).nullish(),
    srcClientVPNServerUUID: z.string().nullish(),
    srcIPSecTunnelUUID: z.string().nullish(),
    srcPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    srcPortRanges: z.array(z.lazy(() => PortRangeInputSchema)).nullish(),
    srcPrefix: z.string().nullish(),
    srcVLANUUID: z.string().nullish(),
    tags: z.array(z.string()).nullish(),
  });

export const UpdateClientVpnServerInputSchema: z.ZodObject<Properties<UpdateClientVpnServerInput>> =
  z.object({
    address: z.string().nullish(),
    addressMask: z.number().nullish(),
    defaultClientAllowedIPs: z.array(z.string()).nullish(),
    dnsListenAddress: z.string().nullish(),
    isFailoverEnabled: z.boolean().nullish(),
    listenPort: z.number().nullish(),
    phyInterfaceUUID: z.string().nullish(),
  });

export const UpdateAutoVpnMemberInputSchema: z.ZodObject<Properties<UpdateAutoVpnMemberInput>> =
  z.object({
    isFailoverEnabled: z.boolean().nullish(),
    permittedVLANUUIDs: z.array(z.string()).nullish(),
    phyInterfaceUUID: z.string().nullish(),
  });

export const UpdateApplicationDnsFirewallRuleInputSchema: z.ZodObject<
  Properties<UpdateApplicationDnsFirewallRuleInput>
> = z.object({
  action: FirewallRuleActionSchema.nullish(),
  applicationID: z.number().nullish(),
  categoryID: z.number().nullish(),
  dhcpRuleUUID: z.string().nullish(),
  domain: z.string().nullish(),
  groupID: z.number().nullish(),
  isEnabled: z.boolean().nullish(),
  name: z.string().nullish(),
  priority: z.number().nullish(),
});

export const RpcWosObserverInputSchema: z.ZodObject<Properties<RpcWosObserverInput>> = z.object({
  SSIDUUID: z.string().nullish(),
  band: RadioBandSchema.nullish(),
  commands: z.array(z.lazy(() => WosObserverCommandInputSchema)).nullish(),
  debugMode: z.boolean().nullish(),
  durationMsec: z.number().nullish(),
  getTestResults: z.boolean().nullish(),
  hostname: z.string().nullish(),
  numSpatialStreams: z.number().nullish(),
  password8021X: z.string().nullish(),
  serialNumber: z.string(),
  targetSerialNumber: z.string().nullish(),
  timeoutMsec: z.number().nullish(),
  transitionUseWpa2: z.boolean().nullish(),
  triggerTest: z.boolean().nullish(),
  use11w: z.boolean().nullish(),
  useScanningRadio: z.boolean().nullish(),
  username8021X: z.string().nullish(),
});

export const RpcwanSpeedtestInputSchema: z.ZodObject<Properties<RpcwanSpeedtestInput>> = z.object({
  phyInterfaceUUID: z.string().nullish(),
  serialNumber: z.string(),
  serverID: z.string().nullish(),
  wan: WanSchema.nullish(),
});

export const RpcPingInputSchema: z.ZodObject<Properties<RpcPingInput>> = z.object({
  burstSize: z.number().default(5),
  host: z.string(),
  intervalSeconds: z.number().default(1),
  numberOfBursts: z.number().default(1),
  phyInterfaceUUID: z.string().nullish(),
  serialNumber: z.string(),
  timeoutSeconds: z.number().default(5),
  vlanUUID: z.string().nullish(),
  wanInterface: z.string().nullish(),
});

export const RpcEapolTestForSerialsInputSchema: z.ZodObject<
  Properties<RpcEapolTestForSerialsInput>
> = z.object({
  Encryption8021XUUID: z.string(),
  password: z.string(),
  serialNumbers: z.array(z.string()),
  ssidUuid: z.string().nullish(),
  user: z.string(),
});

export const RpcEapolTestForSerialInputSchema: z.ZodObject<Properties<RpcEapolTestForSerialInput>> =
  z.object({
    Encryption8021XUUID: z.string(),
    password: z.string(),
    serialNumber: z.string(),
    ssidUuid: z.string().nullish(),
    user: z.string(),
  });

export const PermissionsInputSchema: z.ZodObject<Properties<PermissionsInput>> = z.object({
  companySlug: z.string().nullish(),
  networkUUID: z.string().nullish(),
});

export const DeleteWifiObserverSettingInputSchema: z.ZodObject<
  Properties<DeleteWifiObserverSettingInput>
> = z.object({
  SSIDUUID: z.string(),
  band: RadioBandSchema,
  targetVirtualDeviceUUID: z.string().nullish(),
  virtualDeviceUUID: z.string(),
});

export const DeleteDeviceHeartbeatInputSchema: z.ZodObject<Properties<DeleteDeviceHeartbeatInput>> =
  z.object({
    UUID: z.string().nullish(),
    token: z.string().nullish(),
  });

export const CreateWifiObserverSettingInputSchema: z.ZodObject<
  Properties<CreateWifiObserverSettingInput>
> = z.object({
  SSIDUUID: z.string(),
  band: RadioBandSchema,
  commands: z.array(z.lazy(() => WosObserverCommandInputSchema)).nullish(),
  durationMsec: z.number(),
  numSpatialStreams: z.number().nullish(),
  password8021X: z.string().nullish(),
  targetVirtualDeviceUUID: z.string().nullish(),
  username8021X: z.string().nullish(),
  virtualDeviceUUID: z.string(),
});

export const CreateWifiIpskInputSchema: z.ZodObject<Properties<CreateWifiIpskInput>> = z.object({
  clientMACs: z.array(z.string()).nullish(),
  label: z.string().nullish(),
  password: z.string(),
  ssidUUID: z.string(),
  vlanUUID: z.string().nullish(),
});

export const CreateVirtualDevicesInputSchema: z.ZodObject<Properties<CreateVirtualDevicesInput>> =
  z.object({
    count: z.number(),
    deviceModel: DeviceModelSchema,
    deviceType: VirtualDeviceTypeSchema,
    labelPrefix: z.string(),
    labelSuffixIndex: z.number().default(1),
    nosUpgradeGroupUUID: z.string().nullish(),
    tagUUIDs: z.array(z.string()).nullish(),
  });

export const CreateVirtualDeviceInputSchema: z.ZodObject<Properties<CreateVirtualDeviceInput>> =
  z.object({
    description: z.string().nullish(),
    deviceModel: DeviceModelSchema,
    deviceType: VirtualDeviceTypeSchema,
    label: z.string(),
    nosUpgradeGroupUUID: z.string().nullish(),
    radioSettings: z.lazy(() => CreateRadioSettingsInputSchema.nullish()),
    switchProfileUUID: z.string().nullish(),
    tagUUIDs: z.array(z.string()).nullish(),
  });

export const CreateSwitchProfileInputSchema: z.ZodObject<Properties<CreateSwitchProfileInput>> =
  z.object({
    encryption8021XGuestVLANUUID: z.string().nullish(),
    encryption8021XUUIDs: z.array(z.string()).nullish(),
    isFLIPEnabled: z.boolean().default(false).nullish(),
    isIGMPSnoopingEnabled: z.boolean().default(false).nullish(),
    isMLDSnoopingEnabled: z.boolean().default(false).nullish(),
    isMulticastFilteringEnabled: z.boolean().default(false).nullish(),
    isVoiceVLANEnabled: z.boolean().default(false).nullish(),
    mtu: z.number().default(1522),
    name: z.string().nullish(),
    networkUUID: z.string(),
    stpBridgePriority: z.number().default(32768).nullish(),
  });

export const CreateStaticRouteInputSchema: z.ZodObject<Properties<CreateStaticRouteInput>> =
  z.object({
    dstPrefix: z.string(),
    gatewayDHCPStaticMappingUUID: z.string().nullish(),
    gatewayIP: z.string().nullish(),
    isEnabled: z.boolean().default(true),
    name: z.string(),
  });

export const CreateSsidInputSchema: z.ZodObject<Properties<CreateSsidInput>> = z.object({
  band2GMinimumBasicRateKbps: z.number().nullish(),
  band5GMinimumBasicRateKbps: z.number().nullish(),
  broadcastSchedule: z.array(z.lazy(() => SsidBroadcastScheduleIntervalInputSchema)).nullish(),
  clientSteeringAssociationSteeringIsEnabled: z.boolean().default(false).nullish(),
  clientSteeringLoadBalancingClientMinThreshold: z.number().nullish(),
  clientSteeringPostassociationSteeringIsEnabled: z.boolean().default(false).nullish(),
  clientSteeringPreassociationRSSIMinThreshold: z.number().nullish(),
  description: z.string().nullish(),
  disabledAccessPointVirtualDeviceUUIDs: z.array(z.string()).nullish(),
  dtimPeriod: z.number().nullish(),
  dynamicVLANMode: SsidDynamicVlanModeSchema.nullish(),
  enabledAccessPointVirtualDeviceUUIDs: z.array(z.string()).nullish(),
  encryption80211wProtectedManagementFramesMode:
    SsidEncryption80211wProtectedManagementFramesModeSchema.nullish(),
  encryptionProtocol: SsidEncryptionProtocolSchema.nullish(),
  is80211axEnabled: z.boolean().default(true).nullish(),
  isBand2GAllowed: z.boolean(),
  isBand5GAllowed: z.boolean(),
  isEnabled: z.boolean(),
  isEnabledForAllAccessPoints: z.boolean(),
  isGuest: z.boolean().default(false),
  isHidden: z.boolean(),
  isIsolateClientsEnabled: z.boolean(),
  isProxyARPEnabled: z.boolean().nullish(),
  isRoaming80211kEnabled: z.boolean().nullish(),
  isRoaming80211rEnabled: z.boolean().nullish(),
  isRoaming80211vEnabled: z.boolean().nullish(),
  nasIdentifier: z.string().nullish(),
  password: z.string().nullish(),
  passwordRotationCadence: SsidPasswordRotationCadenceSchema.nullish(),
  passwordRotationDayOfMonthLocal: z.number().nullish(),
  passwordRotationDayOfWeekLocal: z.number().nullish(),
  passwordRotationHourOfDayLocal: z.number().nullish(),
  primaryEncryption8021XUUID: z.string().nullish(),
  radiusCoaEnabled: z.boolean().nullish(),
  radiusCoaPort: z.number().nullish(),
  rtsCtsEnabledCode: z.number().nullish(),
  rtsThreshold: z.number().nullish(),
  secondaryEncryption8021XUUID: z.string().nullish(),
  ssid: z.string(),
  vlanUUID: z.string().nullish(),
});

export const CreatePortForwardingRuleInputSchema: z.ZodObject<
  Properties<CreatePortForwardingRuleInput>
> = z.object({
  allowedRemoteIPAddresses: z.array(z.string()).nullish(),
  description: z.string().nullish(),
  externalIPAddress: z.string().nullish(),
  externalPhyInterfaceUUID: z.string().nullish(),
  externalPort: z.number(),
  isEnabled: z.boolean(),
  localIPAddress: z.string(),
  localPort: z.number(),
  name: z.string(),
  protocol: PortIpProtocolSchema,
});

export const CreatePolicyRoutingRuleInputSchema: z.ZodObject<
  Properties<CreatePolicyRoutingRuleInput>
> = z.object({
  bindings: z.array(z.lazy(() => PolicyRoutingRuleBindingInputSchema)),
  dstPortRanges: z.array(z.lazy(() => PortRangeInputSchema)),
  dstPrefix: z.string(),
  isEnabled: z.boolean(),
  name: z.string(),
  protocols: z.array(IpProtocolSchema),
  srcPortRanges: z.array(z.lazy(() => PortRangeInputSchema)),
  srcPrefix: z.string().nullish(),
  srcVLANUUID: z.string().nullish(),
});

export const CreateNetworkThreatAllowlistEntryInputSchema: z.ZodObject<
  Properties<CreateNetworkThreatAllowlistEntryInput>
> = z.object({
  reason: z.string().nullish(),
  ssidUUID: z.string().nullish(),
  wiredMAC: z.string().nullish(),
});

export const CreateNetworkInputSchema: z.ZodObject<Properties<CreateNetworkInput>> = z.object({
  IsWifiSteeringEnabled: z.boolean().default(true),
  companySlug: z.string().nullish(),
  isActive: z.boolean().default(true),
  isConfig1WosUpgradesEnabled: z.boolean().default(true),
  isTemplate: z.boolean().default(false),
  isTest: z.boolean().default(false),
  label: z.string(),
  mailingAddress: z.string().nullish(),
  mailingAddressInput: z.lazy(() => CreateMailingAddressInputSchema.nullish()),
  mailingAddressUUID: z.string().nullish(),
  multiWANAlgorithm: MultiWanAlgorithmSchema.nullish(),
  patchPanelDiagramURL: z.string().nullish(),
  pinnedNOSVersionID: z.number().nullish(),
  slug: z.string(),
  squareFeet: z.number().nullish(),
  staggeredUpgradeIntervalMin: z.number().nullish(),
  topologyImageS3Key: z.string().nullish(),
});

export const CreateHighAvailabilityPairInputSchema: z.ZodObject<
  Properties<CreateHighAvailabilityPairInput>
> = z.object({
  advertisementIntervalMs: z.number().default(300),
  advertisementVLANID: z.number().nullish(),
  advertisementVLANUUID: z.string().nullish(),
  createAdvertisementVLANInput: z.lazy(() => CreateVlanInputSchema.nullish()),
  preempt: z.boolean(),
});

export const CreateFirewallRuleInputSchema: z.ZodObject<Properties<CreateFirewallRuleInput>> =
  z.object({
    action: FirewallRuleActionSchema,
    boundPhyInterfaceUUIDs: z.array(z.string()),
    boundVLANUUIDs: z.array(z.string()),
    description: z.string().nullish(),
    dstClientVPNServerUUID: z.string().nullish(),
    dstIPSecTunnelUUID: z.string().nullish(),
    dstPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    dstPortRanges: z.array(z.lazy(() => PortRangeInputSchema)).nullish(),
    dstPrefix: z.string().nullish(),
    dstVLANUUID: z.string().nullish(),
    isBidirectional: z.boolean().nullish(),
    isEnabled: z.boolean(),
    isMeterInternal: z.boolean(),
    name: z.string(),
    priority: z.number().nullish(),
    protocol: IpProtocolSchema.nullish(),
    protocols: z.array(IpProtocolSchema).nullish(),
    srcClientVPNServerUUID: z.string().nullish(),
    srcIPSecTunnelUUID: z.string().nullish(),
    srcPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    srcPortRanges: z.array(z.lazy(() => PortRangeInputSchema)).nullish(),
    srcPrefix: z.string().nullish(),
    srcVLANUUID: z.string().nullish(),
    tags: z.array(z.string()).nullish(),
  });

export const CreateClientVpnServerInputSchema: z.ZodObject<Properties<CreateClientVpnServerInput>> =
  z.object({
    address: z.string(),
    addressMask: z.number(),
    defaultClientAllowedIPs: z.array(z.string()),
    dnsListenAddress: z.string().nullish(),
    isFailoverEnabled: z.boolean().default(true),
    listenPort: z.number().default(51820).nullish(),
    phyInterfaceUUID: z.string().nullish(),
  });

export const CheckRoleInputSchema: z.ZodObject<Properties<CheckRoleInput>> = z.object({
  companySlug: z.string().nullish(),
  networkUUID: z.string().nullish(),
  roleName: RoleNameSchema,
});

export const AttachDeviceToRackElevationInputSchema: z.ZodObject<
  Properties<AttachDeviceToRackElevationInput>
> = z.object({
  isFrontFacing: z.boolean().nullish(),
  label: z.string().nullish(),
  notes: z.string().nullish(),
  portCount: z.number().nullish(),
  rackMountUnitIndexes: z.array(z.number()),
  type: RackElevationDeviceTypeSchema.nullish(),
  virtualDeviceUUID: z.string().nullish(),
});

export const UpdateRateLimitRuleInputSchema: z.ZodObject<Properties<UpdateRateLimitRuleInput>> =
  z.object({
    boundPhyInterfaceUUIDs: z.array(z.string()).nullish(),
    boundVLANUUIDs: z.array(z.string()).nullish(),
    description: z.string().nullish(),
    dstMac: z.string().nullish(),
    dstPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    dstPrefix: z.string().nullish(),
    isEnabled: z.boolean().nullish(),
    isPerSource: z.boolean().nullish(),
    name: z.string().nullish(),
    protocol: IpProtocolSchema.nullish(),
    rateLimitKbpsDownload: z.number().nullish(),
    rateLimitKbpsUpload: z.number().nullish(),
    srcMac: z.string().nullish(),
    srcPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    srcPrefix: z.string().nullish(),
  });

export const UpdateDhcpStaticMappingInputSchema: z.ZodObject<
  Properties<UpdateDhcpStaticMappingInput>
> = z.object({
  hostname: z.string().nullish(),
  ipAddress: z.string().nullish(),
  macAddress: z.string().nullish(),
  name: z.string().nullish(),
});

export const NetworkClientsFilterSchema: z.ZodObject<Properties<NetworkClientsFilter>> = z.object({
  apSerialNumber: z.string().nullish(),
  excludeMeterHardware: z.boolean().default(false),
  includeLatency: z.boolean().default(false),
  includeThroughput: z.boolean().default(false),
  lookbackMinutes: z.number().default(5),
  macAddress: z.string().nullish(),
  ssid: z.string().nullish(),
  vlanID: z.number().nullish(),
});

export const CreateSwitchAutoConfigMacAddressVlanBindingInputSchema: z.ZodObject<
  Properties<CreateSwitchAutoConfigMacAddressVlanBindingInput>
> = z.object({
  OUI: z.string().nullish(),
  VLANUUID: z.string(),
  macAddress: z.string().nullish(),
  vendor: z.string().nullish(),
});

export const CreateRateLimitRuleInputSchema: z.ZodObject<Properties<CreateRateLimitRuleInput>> =
  z.object({
    boundPhyInterfaceUUIDs: z.array(z.string()).nullish(),
    boundVLANUUIDs: z.array(z.string()).nullish(),
    description: z.string().nullish(),
    dstMac: z.string().nullish(),
    dstPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    dstPrefix: z.string().nullish(),
    isEnabled: z.boolean(),
    isPerSource: z.boolean().nullish(),
    name: z.string(),
    protocol: IpProtocolSchema.nullish(),
    rateLimitKbpsDownload: z.number().nullish(),
    rateLimitKbpsUpload: z.number().nullish(),
    srcMac: z.string().nullish(),
    srcPortRange: z.lazy(() => PortRangeInputSchema.nullish()),
    srcPrefix: z.string().nullish(),
  });

export const CreateDevHardwareDeviceInputSchema: z.ZodObject<
  Properties<CreateDevHardwareDeviceInput>
> = z.object({
  deviceModel: DeviceModelSchema,
  deviceType: DeviceTypeSchema,
  macAddress: z.string().nullish(),
  serialNumber: z.string(),
});

export const CreateDhcpRuleFilterInputSchema: z.ZodObject<Properties<CreateDhcpRuleFilterInput>> =
  z.object({
    allowedMACAddress: z.string().nullish(),
    allowedOUI: z.string().nullish(),
  });

export const UpdateMarketingChangelogEntryInputSchema: z.ZodObject<
  Properties<UpdateMarketingChangelogEntryInput>
> = z.object({
  copy: z.string().nullish(),
  heading: z.string().nullish(),
  imageURL: z.string().nullish(),
  linkURL: z.string().nullish(),
  visibleEndAt: z.string().nullish(),
  visibleStartAt: z.string().nullish(),
});

export const SystemInfoStatsFilterSchema: z.ZodObject<Properties<SystemInfoStatsFilter>> = z.object(
  {
    durationSeconds: z.number(),
    endTime: z.string().nullish(),
    limit: z.number().default(100),
  },
);

export const StatsFilterSchema: z.ZodObject<Properties<StatsFilter>> = z.object({
  durationSeconds: z.number(),
  endTime: z.string().nullish(),
  limit: z.number().default(100),
  offset: z.number().default(0),
  types: z.array(StatTypeSchema),
});

export const NetworksDateTimeFilterSchema: z.ZodObject<Properties<NetworksDateTimeFilter>> =
  z.object({
    gt: z.string().nullish(),
    lt: z.string().nullish(),
  });

export const NetworksFilterSchema: z.ZodObject<Properties<NetworksFilter>> = z.object({
  active: NetworksBooleanFilterSchema.nullish(),
  companySupportTier: NetworksCompanySupportTierFilterSchema.nullish(),
  hardwareModel: NetworksHardwareModelFilterSchema.nullish(),
  isTrial: NetworksBooleanFilterSchema.nullish(),
  lastUpgradedAt: NetworksDateTimeFilterSchema.nullish(),
  mailingAddressSquareFeet: NetworksIntFilterSchema.nullish(),
  nosVersionID: NetworksNosVersionIdFilterSchema.nullish(),
  pendingUpgrade: NetworksBooleanFilterSchema.nullish(),
  upgradeSensitivity: NetworksBooleanFilterSchema.nullish(),
});

export const NetworkStatsFilterSchema: z.ZodObject<Properties<NetworkStatsFilter>> = z.object({
  durationSeconds: z.number(),
  endTime: z.string().nullish(),
  limit: z.number().default(100),
  offset: z.number().default(0),
  types: z.array(StatTypeSchema),
  virtualDeviceUUIDs: z.array(z.string()),
});

export const MutationDiffAuditLogFilterInputSchema: z.ZodObject<
  Properties<MutationDiffAuditLogFilterInput>
> = z.object({
  includeInternal: z.boolean().nullish(),
  limit: z.number().default(100),
  maxCreatedAt: z.string().nullish(),
  minCreatedAt: z.string().nullish(),
  offset: z.number().default(0),
});

export const MutationAuditLogFilterSchema: z.ZodObject<Properties<MutationAuditLogFilter>> =
  z.object({
    includeMachine: z.boolean().default(false),
    limit: z.number().default(100),
    maxCreatedAt: z.string().nullish(),
    minCreatedAt: z.string().nullish(),
    offset: z.number().default(0),
  });

export const MetricsFilterInputSchema: z.ZodObject<Properties<MetricsFilterInput>> = z.object({
  durationSeconds: z.number(),
  endTime: z.string().nullish(),
  stepSeconds: z.number(),
});

export const CreateUserNetworkAccessInputSchema: z.ZodObject<
  Properties<CreateUserNetworkAccessInput>
> = z.object({
  expiresAt: z.string().nullish(),
  networkUUID: z.string(),
});

export const BulkNosUpgradesFilterInputSchema: z.ZodObject<Properties<BulkNosUpgradesFilterInput>> =
  z.object({
    limit: z.number().default(100),
    maxCreatedAt: z.string().nullish(),
    minCreatedAt: z.string().nullish(),
    offset: z.number().default(0),
  });

export const AnomalyStatsFilterInputSchema: z.ZodObject<Properties<AnomalyStatsFilterInput>> =
  z.object({
    durationSeconds: z.number(),
    endTime: z.string().nullish(),
    limit: z.number().default(100),
    offset: z.number().default(0),
    virtualDeviceUUIDs: z.array(z.string()),
  });

export const AlertAuditLogFilterSchema: z.ZodObject<Properties<AlertAuditLogFilter>> = z.object({
  alertName: z.string().nullish(),
  limit: z.number().default(100),
  maxTriggeredAt: z.string().nullish(),
  minTriggeredAt: z.string().nullish(),
  offset: z.number().default(0),
});
