import type { UseGraphQLOptions } from '@meterup/graphql';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { orderBy } from 'lodash-es';
import { useMemo } from 'react';

import type { NetworkClientsFilter, NetworkClientsQuery } from '../../gql/graphql';
import type { Network } from '../useNetworksForCompany';
import { graphql } from '../../gql';

export type NetworkClient = NetworkClientsQuery['networkClients'][number];

export type NetworkClientOptions = {
  deviceName?: string;
  filter?: NetworkClientsFilter;
};

export const getNetworkClients = graphql(`
  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {
    networkClients(networkUUID: $networkUUID, filter: $filter) {
      alias
      apLocation
      apSerialNumber
      channel
      clientName
      ip
      isWireless
      lastSeen
      dhcpLeaseExpiresAt
      macAddress
      noise
      radioBand
      totalRxBytes
      rxRate
      signal
      ssid
      txRate
      totalTxBytes
      vlan
      vlanUUID
      hwMode
      connectedVLAN {
        UUID
        name
        vlanID
      }
      associatedAt
      throughput {
        txRate
        rxRate
      }
      serviceTypes
      wirelessScore
      staticMapping {
        UUID
        name
        macAddress
        ipAddress
        hostname
      }
    }
  }
`);

export const useNetworkClients = (
  network?: Network,
  options?: NetworkClientOptions,
  useGraphQLOptions?: UseGraphQLOptions<NetworkClientsQuery>,
) => {
  if (
    isDefinedAndNotEmpty(options?.filter?.apSerialNumber) &&
    isDefinedAndNotEmpty(options?.filter?.macAddress)
  ) {
    throw new Error('Cannot pass both a apSerialNumber and macAddress');
  }
  const networkClients = useGraphQL(
    getNetworkClients,
    {
      // not to worry, this will never happen b/c enabled is false below.
      networkUUID: network?.UUID ?? '',
      filter: {
        excludeMeterHardware: false,
        includeThroughput: false,
        ...options?.filter,
      },
    },
    {
      enabled: !!network?.UUID,
      ...useGraphQLOptions,
    },
  ).data?.networkClients;
  return useMemo(() => orderBy(networkClients ?? [], (d) => d.lastSeen, 'desc'), [networkClients]);
};
