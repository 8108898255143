/**
 * Pluralizes a string based on a count
 * @param count
 * @param input The string to pluralize
 * @param plural The plural form of the string, defaults to `input + "s"`
 */
export const pluralize = (count: number, input: string, plural: string = `${input}s`) =>
  count === -1 || count === 1 ? input : plural;

export const ucfirst = (input: string) => `${input.charAt(0).toUpperCase()}${input.substring(1)}`;
export const lcfirst = (input: string) => `${input.charAt(0).toLowerCase()}${input.substring(1)}`;

export const screamingSnakeCaseToWords = (input: string) => {
  if (input === 'DSL') {
    return 'DSL';
  }
  return ucfirst(input.toLowerCase().replaceAll(/_+/g, ' '));
};

export const titleCase = (input: string) =>
  input
    .toLowerCase()
    .replaceAll(/[_-]+/g, ' ')
    .split(' ')
    .map((word) => ucfirst(word))
    .join(' ');

export const getInitials = (input: string, length: number) =>
  input
    .split(/\s+/)
    .map((word) =>
      word
        .split('-')
        .map((part) => part.charAt(0).toUpperCase())
        .join(''),
    )
    .join('')
    .substring(0, length);

export const dayOfWeek = (num: number) =>
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][num];

export const hourOfDay = (hour: number) => {
  const v = hour % 12;
  const m = hour >= 12 ? 'PM' : 'AM';
  return v === 0 ? `12${m}` : `${v.toString()}${m}`;
};

export const numberToOrdinal = (num: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = num % 100;
  return num + (s[(v - 20) % 10] || s[v] || s[0]);
};

// src: https://gist.github.com/codeguy/6684588?permalink_comment_id=4325476#gistcomment-4325476
export function sluggify(text: string) {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
}
