import type { BadgeVariant } from '@meterup/atto';
import { Badge } from '@meterup/atto';
import { ScheduledNosUpgradeStatus } from '@meterup/common/src/gql/graphql';
import { useGraphQL } from '@meterup/graphql';
import { z } from 'zod';

import { graphql } from '../../../gql';
import {
  type NosUpgradeGroupsForNetworkQuery,
  type ScheduledNetworkNosUpgradesforNetworkQuery,
} from '../../../gql/graphql';
import {
  CreateNosUpgradeGroupInputSchema,
  SetNetworkNosVersionInputSchema,
} from '../../../gql/zod-types';

export enum ValidNOSUpgradesTab {
  Groups = 'groups',
  Upgrades = 'upgrades',
}

export type NOSUpgradeGroup = NonNullable<
  NosUpgradeGroupsForNetworkQuery['nosUpgradeGroupsForNetwork']
>[number];

export const nosUpgradeGroupsForNetworkQuery = graphql(`
  query NOSUpgradeGroupsForNetwork($networkUUID: UUID!) {
    nosUpgradeGroupsForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      priority
      updatedAt
      description
      defaultDeviceType
      defaultDeviceSubtype
      networkUUID
      timeoutDurationSeconds
      virtualDevices {
        label
        UUID
        deviceType
        deviceModel
        network {
          slug
          companySlug
        }
        hardwareDevice {
          macAddress
        }
      }
    }
  }
`);

export type ScheduledNOSUpgrade = NonNullable<
  ScheduledNetworkNosUpgradesforNetworkQuery['scheduledNetworkNOSUpgradesForNetwork']
>[number];

export const scheduledNetworkNOSUpgradesForNetworkQuery = graphql(`
  query ScheduledNetworkNOSUpgradesforNetwork($networkUUID: UUID!) {
    scheduledNetworkNOSUpgradesForNetwork(networkUUID: $networkUUID) {
      UUID
      networkUUID
      nosVersion {
        id
        version
        name
        major
        minor
        patch
      }
      scheduledAt
      completedAt
      status
      pendingNOSUpgradeGroups {
        UUID
        name
      }
      groupUpgrades {
        UUID
        nosUpgradeGroup {
          UUID
          name
        }
        scheduledAt
        completedAt
        status

        deviceUpgrades {
          virtualDevice {
            label
          }
          status
          scheduledAt
          completedAt
        }
      }
    }
  }
`);

export const createNOSUpgradeGroupInputSchema = CreateNosUpgradeGroupInputSchema.extend({
  timeoutDurationMinutes: z
    .number()
    .min(0, { message: 'Duration cannot be negative' })
    .max(20, { message: 'Duration cannot be greater than 20 minutes' }),
  timeoutDurationConfirmed: z.boolean(),
}).superRefine(({ timeoutDurationMinutes, timeoutDurationConfirmed }, ctx) => {
  if (!timeoutDurationConfirmed && timeoutDurationMinutes < 3) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must confirm duration less than 3 minutes',
      path: ['timeoutDurationConfirmed'],
    });
  }
});
export type NOSUpgradeGroupFormValues = z.infer<typeof createNOSUpgradeGroupInputSchema>;

export const createNOSUpgradeGroupMutation = graphql(`
  mutation CreateNOSUpgradeGroup($networkUUID: UUID!, $input: CreateNOSUpgradeGroupInput!) {
    createNOSUpgradeGroup(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateNOSUpgradeGroupMutation = graphql(`
  mutation UpdateNOSUpgradeGroup($uuid: UUID!, $input: UpdateNOSUpgradeGroupInput!) {
    updateNOSUpgradeGroup(nosUpgradeGroupUUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteNOSUpgradeGroupMutation = graphql(`
  mutation DeleteNOSUpgradeGroup($uuid: UUID!) {
    deleteNOSUpgradeGroup(nosUpgradeGroupUUID: $uuid) {
      UUID
    }
  }
`);

export const cancelNOSUpgradeMutation = graphql(`
  mutation CancelNOSUpgrade($uuid: UUID!, $force: Boolean!) {
    cancelScheduledNetworkNOSUpgrade(
      scheduledNetworkNOSUpgradeUUID: $uuid
      forceInProgressCancel: $force
    ) {
      UUID
    }
  }
`);

export const setNetworkNOSVersionInputSchema = SetNetworkNosVersionInputSchema.extend({});
export type NOSUpgradeFormValues = z.infer<typeof setNetworkNOSVersionInputSchema>;

export const setNetworkNOSVersionMutation = graphql(`
  mutation SetNetworkNOSVersion($networkUUID: UUID!, $input: SetNetworkNosVersionInput!) {
    setNetworkNosVersion(networkUUID: $networkUUID, input: $input)
  }
`);

export function useUpgradeDurationForNetwork(networkUUID: string) {
  const allUpgrades = useGraphQL(nosUpgradeGroupsForNetworkQuery, { networkUUID }).data
    ?.nosUpgradeGroupsForNetwork;

  if (!allUpgrades || allUpgrades.length === 0) {
    return -1;
  }

  return allUpgrades
    .filter((g) => g.virtualDevices.length > 0)
    .reduce((acc, curr) => acc + curr.timeoutDurationSeconds, 0);
}

export function nosUpgradeNOSVersion(upgrade: ScheduledNOSUpgrade, includeName = false) {
  let str = `${upgrade.nosVersion.major}.${upgrade.nosVersion.minor}.${upgrade.nosVersion.patch}`;
  if (includeName) {
    str = `${str} (${upgrade.nosVersion.version})`;
  }
  return str;
}

export function canCancelNetworkUpgrade(upgrade: ScheduledNOSUpgrade) {
  return [ScheduledNosUpgradeStatus.InProgress, ScheduledNosUpgradeStatus.Scheduled].includes(
    upgrade.status,
  );
}

export function cancelingNetworkUpgradeRequiresForce(upgrade: ScheduledNOSUpgrade) {
  return upgrade.status === ScheduledNosUpgradeStatus.InProgress;
}

export function upgradeStatusInFinalState(status: ScheduledNosUpgradeStatus) {
  return [
    ScheduledNosUpgradeStatus.Completed,
    ScheduledNosUpgradeStatus.Failed,
    ScheduledNosUpgradeStatus.Cancelled,
  ].includes(status);
}

export function NOSUpgradeStatusBadge({ status }: { status: ScheduledNosUpgradeStatus }) {
  let variant: BadgeVariant = 'neutral';
  let text = 'Scheduled';
  switch (status) {
    case ScheduledNosUpgradeStatus.Failed:
      variant = 'negative';
      text = 'Failed';
      break;
    case ScheduledNosUpgradeStatus.Cancelled:
      variant = 'attention';
      text = 'Canceled';
      break;
    case ScheduledNosUpgradeStatus.Completed:
      variant = 'positive';
      text = 'Completed';
      break;
    case ScheduledNosUpgradeStatus.InProgress:
      variant = 'brand';
      text = 'In Progress';
      break;
  }

  return (
    <Badge variant={variant} size="small" ends="pill">
      {text}
    </Badge>
  );
}
